import { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchAllActions, fetchNotes, fetchSessions } from "../actions/storage-actions";
import {
  formatDate,
  formatHour,
  getAllConvertedStrokes,
  getGlobalBB,
} from "../helpers";
import { Navigate, useNavigate } from 'react-router-dom';
import { getNotes, getSessions } from "../reducers/storage-reducer";
import Header from "./Header";
import { getDriveFiles } from "../reducers/google-reducer";
import { fetchDriveFiles, syncNoteToDrive } from "../actions/google-actions";

const Wrapper = (props: any) => {
  const children: any = props.children;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <div style={{ width: "70rem", maxWidth: "80vw", marginTop: "5rem", overflow: "auto" }}>{children}</div>
    </div>
  );
};

const WelcomeMessage = styled.p`
  font: normal normal normal 25px/22px TT Norms Pro;
  color: #0a0a0a;
`;

const FBWrapper = styled.div`
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 4rem;

  & > p {
    display: flex;
    align-items: center;
    font: normal normal 500 14px/22px TT Norms Pro;
    letter-spacing: 0px;
    color: #0a0a0a;
  }
`;

const Arrow = () => (
  <svg
    style={{ zoom: 0.7 }}
    className=" a-s-fa-Ha-pa"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    focusable="false"
    fill="#000000"
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

const Input = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100%;
    background-image: url(/images/search_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  & > input {
    height: 2rem;
    padding-left: 2rem;
  }
`;

const SessionsWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3.3rem;
  padding-bottom: 10rem;
  & .note {
    width: 15rem;
    height: 15rem;
    border: 1px solid black;
    position: relative;
    cursor: pointer;
  }
`;

const NotesWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3.3rem;
  padding-bottom: 10rem;
  & .note {
    >div {
      ::-webkit-scrollbar {
        display: none;
      }

      height: calc(100% - 2rem);
      overflow: auto;
      line-height: 1rem;
      margin: 1rem;
      box-sizing: border-box;
       p {
        margin: 0;
        margin-bottom: 0.7rem;
       }
    }
    width: 15rem;
    height: 15rem;
    border: 1px solid black;
    position: relative;
    cursor: pointer;
  }
`;

const NoteTitle = styled.p`
  font: normal normal normal 14px/33px TT Norms Pro;
  letter-spacing: 0px;
  color: #51575d;
  display: flex;
  align-items: center;
  margin-top: 0;
  & img {
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const CreateNotePreview = (props: any) => {
  console.log("CreateNotePreview", props.driveFile)
  return (
    <div className="note" onClick={() => {
      const link = `https://drive.google.com/file/d/${props.driveFile.id}/view`;
      // @ts-ignore
      window.open(link, '_blank').focus();
    }}>
      <div dangerouslySetInnerHTML={{ __html: `<p>${props.driveFile.fileContent.split('\n').join('</p><p>')}</p>` }}></div>
      <NoteTitle>
        <img src="/images/note_icon_synced.svg" />
        <h3 style={{ margin: 0 }}>{props.driveFile.name}</h3>
      </NoteTitle>

    </div>
  )
}
const CreateSessionPreview = (props: any) => {
  const session: any = props.session;
  console.log("SESSION PREVIEW", session, getGlobalBB(session.elements));
  const strokes = getAllConvertedStrokes(session, 5);
  const final_data = {
    width: 240,
    height: 240,
    // lines: [...textStrokes, ...otherStrokes]
    lines: strokes,
  };
  const canvasRef = useRef<any>(null);

  const navigate = useNavigate()
  return (
    <div className="note" onClick={() => navigate(`/session/${session._id}`)} >
      <CanvasDraw
        canvasWidth={final_data.width}
        canvasHeight={final_data.height}
        disabled
        hideGrid
        backgroundColor="transparent"
        immediateLoading={true}
        // ref={canvasDraw => (this.loadableCanvas = canvasDraw)}
        ref={canvasRef}
        saveData={JSON.stringify(final_data)}
      // saveData={JSON.stringify(data)}
      />
      <NoteTitle>
        <img src="/images/note_icon.svg" />
        {`${formatDate(session.timestamp)} ${formatHour(session.timestamp)}`}
      </NoteTitle>
    </div>
  );
};

const MyFiles = () => {
  const dispatch = useDispatch();
  const sessions = useSelector(getSessions);
  const notes = useSelector(getNotes);
  const driveFiles = useSelector(getDriveFiles);

  const [searchSessions, setSearchSessions] = useState("");
  const [searchNotes, setSearchNotes] = useState("");



  return (
    <>
      <Header />
      <Wrapper>
        <WelcomeMessage>Bienvenue, Erwan!</WelcomeMessage>
        <FBWrapper>
          <p>
            <Arrow /> MES SESSIONS
          </p>
          <Input>
            <input type="text" value={searchSessions} onChange={(e) => setSearchSessions(e.target.value)} />
          </Input>
        </FBWrapper>
        <SessionsWrapper>
          {(sessions || [])
            .sort(
              (a: any, b: any) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .filter((session: any) => {
              const text = session.elements.map((element: any) => element?.text?.label || "").join(" ");
              return text.toLowerCase().includes(searchSessions.toLowerCase());
            })
            .map((session: any) => (
              <CreateSessionPreview session={session} />
            ))}
        </SessionsWrapper>
        <FBWrapper>
          <p>
            GOOGLE DRIVE <Arrow /> MES NOTES
          </p>
          <Input>
            <input type="text" value={searchNotes} onChange={(e) => setSearchNotes(e.target.value)} />
          </Input>
        </FBWrapper>
        <NotesWrapper>
          {(driveFiles || [])
            .sort(
              (a: any, b: any) => {
                if (a.name < b.name)
                  return -1;
                if (a.name > b.name)
                  return 1;
                return 0;
              }
            )
            .filter((driveFile: any) => driveFile.name.toLowerCase().includes(searchNotes.toLowerCase()) || driveFile.fileContent.toLowerCase().includes(searchNotes.toLowerCase()))
            .map((driveFile: any) => (
              <CreateNotePreview driveFile={driveFile} />
            ))}
        </NotesWrapper>
      </Wrapper>
    </>
  );
};

export default MyFiles;
