import { useEditor, EditorContent, Editor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import note1 from '../../notes/note1';
import note2 from '../../notes/note2';
import note3 from '../../notes/note3';
import note4 from '../../notes/note4';
import HandWriting from './customExtensions/react-renderer';
import StyloParagraph from './customExtensions/stylo-paragraph';

export const createBlock = (session: any, actions: any) => {
    const block = {
        "type": "stylo-react-renderer",
        "attrs": {
            // data read by custom hand-writing tiptap extension
            "data": JSON.stringify(session)
        },
        // textual content rendered by default tiptap (p)
        "content": session.elements
            .filter((element: any) => element?.type == "text")
            .map((element: any) => {
                return {
                    "type": "styloParagraph",
                    "attrs": {
                        "mail-body": actions.filter((action: any) => action.type == "mail")
                            .filter((action: any) =>
                                action["mail"].body.some((elem: any) => elem.id == element.id)
                            ),
                        "mail-recipient": actions.filter((action: any) => action.type == "mail")
                            .filter((action: any) =>
                                action["mail"].recipient.id_original_elt == element.id
                            ),
                        "contact-data": actions.filter((action: any) => action.type == "contact")
                            .filter((action: any) =>
                                action["contact"].id_original_elt_content.includes(element.id)
                            )
                    },
                    "content": element?.text?.label.split("\n")
                        .filter((line: any) => line)
                        .map((line: any) => [
                            {
                                "type": "text",
                                "text": line//.replaceAll("\n", "<br/>"),
                            },
                            {
                                "type": "hardBreak"
                            },
                        ]).flat()
                }
            })
    }

    console.log("BLOCK", block)
    return block;
}

const editor = new Editor({
    extensions: [
        StarterKit.configure({
            hardBreak: {
                keepMarks: true
            }
        }),
        HandWriting,
        StyloParagraph,
    ],
    content: {
        "type": "doc",
        "content": [
            createBlock(note3, [])
        ]
    },
    onUpdate: () => {
        console.log("UPDATEDD", editor.getJSON());
    }
})

console.log("EDITORRR", editor)

export default editor;