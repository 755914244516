import {
  SET_ACTIONS,
  SET_SESSIONS,
  SET_OPEN_ACTION_ID,
  SET_NOTES
} from '../actions/storage-actions';

export default function storageReducer(state='', { type, payload }) {
  switch (type) {
    case SET_SESSIONS:
    return {
      ...state,
      sessions: payload
    }
    case SET_NOTES:
    return {
      ...state,
      notes: payload
    }
    case SET_ACTIONS:
    return {
      ...state,
      actions: payload
    }
    case SET_OPEN_ACTION_ID:
    return {
      ...state,
      openActionId: payload
    }
    default:
    return state;
  }
}


// selectors
export function getSessions(state) {
  return state.storageReducer.sessions;
}

export function getNotes(state) {
  return state.storageReducer.notes;
}

export function getActions(state) {
  return state.storageReducer.actions;
}

export function getOpenActionId(state) {
  return state.storageReducer.openActionId;
}
