import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { logIn } from '../actions/login-actions';
import { getIsLoggedIn } from '../reducers/login-reducer';

const Login = ({ children }) => {
  
  const dispatch = useDispatch();
  const isLoggedIn = useReducer(getIsLoggedIn);
  console.log("IS LOGGED IN", isLoggedIn)

  const onSubmit = () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    dispatch(logIn(email, password));
  }

  const LoginPage = () => {
    return (
      <Grid container component="main" className="loginRoot">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className="loginImage" />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className="loginPaper">
            <Avatar className="loginAvatar">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <div className="loginForm" >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                value="erwan.boehm@seed-up.io"
                label="Addresse Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                value="passw0rd"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Se souvenir de moi"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="loginSubmit"
                onClick={onSubmit}
              >
                Se connecter
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Mot de passe oublié?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Pas de compte? S'enregistrer"}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  return isLoggedIn ? children : <LoginPage />
}

export default Login;
