import './css/index.css';
import 'react-notifications-component/dist/theme.css'
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import thunk from 'redux-thunk';
import { createHashHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import googleReducer from './reducers/google-reducer';
import loginReducer from './reducers/login-reducer';
import storageReducer from './reducers/storage-reducer';
import Login from './components/Login';
import EditorContainer from './components/Editor/DocumentEditor';
import { IsHandWrittingActiveProvider } from './components/useIsHandWritten';
import MyFiles from './components/MyFiles';
import GoogleConnect from './components/GoogleConnect';

const allReducers = combineReducers({
  googleReducer: googleReducer,
  loginReducer: loginReducer,
  storageReducer: storageReducer
});

const allStoreEnhancers = composeWithDevTools(
  applyMiddleware(thunk),
);

const store = createStore(
  allReducers,
  {
    googleReducer: {
      contacts: [],
      driveFiles: [],
    },
    loginReducer: {
      loggedIn: localStorage.getItem("user"),
    },
    storageReducer: {
      notes: [],
      sessions: [],
      actions: [],
      openActionId: null,
    }
  },
  allStoreEnhancers
);

// put after login
ReactDOM.render(
  <Provider store={store}>
    <Login>
      <IsHandWrittingActiveProvider>

        <GoogleConnect>
          <ReactNotification />
          <BrowserRouter>
            <Routes>
              <Route path="/session/:session_id" element={<EditorContainer type="sessopm"/>} />
              <Route path="/note/:note_id" element={<EditorContainer type="note" />} />
              <Route path="/" element={<MyFiles />} />
            </Routes>
          </BrowserRouter>
        </GoogleConnect>
      </IsHandWrittingActiveProvider>
    </Login>
  </Provider >
  , document.getElementById('root')
);
