import axios from "axios";
import { notify } from "../utils/notifications";
import { syncNoteToDrive } from "./google-actions";
// const STORAGE_URL = `http://localhost:9002`;
const STORAGE_URL = `https://storage.stylo.seed-up.io`;

// add JWT at each request
axios.interceptors.request.use(function (config) {
  try {
    const token = JSON.parse(localStorage.getItem("user")).token.split(" ")[1];
    config.headers.Authorization = `JWT ${token}`;
    return config;
  } catch (e) {
    return config;
  }
});

export const SET_SESSIONS = "storage:SET_SESSIONS";
export const SET_ACTIONS = "storage:SET_ACTIONS";
export const SET_OPEN_ACTION_ID = "storage:SET_OPEN_ACTION_ID";
export const SET_NOTES = "storage:SET_NOTES";

// Block
export function setSessions(sessions) {
  return {
    type: SET_SESSIONS,
    payload: sessions,
  };
}

export function setNotes(notes) {
  return {
    type: SET_NOTES,
    payload: notes,
  };
}

export function setOpenActionId(data) {
  return {
    type: SET_OPEN_ACTION_ID,
    payload: data,
  };
}

export function fetchSessions(dispatch) {
  axios
    .get(`${STORAGE_URL}/session`)
    .then((resp) => resp.data) // Transform the data into json
    .then(function (sessions) {
      if (!sessions) {
        notify("Aucune sessions", "", "warning", true);
        return dispatch(setSessions([]));
      }
      // notify(`${sessions.length} sessions`, ` `, "success", false);
      return dispatch(setSessions(sessions));
    });
}


export function fetchNotes(dispatch) {
  axios
    .get(`${STORAGE_URL}/notes`)
    .then((resp) => resp.data) // Transform the data into json
    .then(async (notes) => {
      if (!notes) {
        notify("Aucune note", "", "warning", true);
        return dispatch(setNotes([]));
      }
      notify(`${notes.filter(n => n.syncedToDrive==false).length} nouvelles notes trouvées`, ` `, "success", false);

      // SYNC NOTES TO DRIVE
      console.log("NOTES", notes)
      const sortedNotes = notes
        .sort(
          (a: any, b: any) =>
            new Date(b.id_original_session).getTime() -
            new Date(a.id_original_session).getTime()
        )
      for (let i = 0; i < sortedNotes.length; i++) {
        const note = sortedNotes[i];
        const title = note.title.label.toLowerCase();
        const text = note.body.map(block => block.label).join("\n");
        const synced = note.syncedToDrive;
        if (!synced) {
          console.log("NOTE TITLE", title)
          // console.log("NOTE TEXT", text)
          await syncNoteToDrive(localStorage.getItem("googleAccessToken"), title, text)
          await setNoteSynced(note._id);
          notify(`Synchronisation de  ${title} réussie !`, ` `, "success", false);
        }

      }

      return dispatch(setNotes(notes));
    });
}

export function setActions(actions) {
  return {
    type: SET_ACTIONS,
    payload: actions,
  };
}

export function fetchAllActions(dispatch) {
  axios
    .get(`${STORAGE_URL}/action`)
    .then((resp) => resp.data) // Transform the data into json
    .then(function (actions) {
      if (!actions) {
        notify("Aucune actions", "", "warning", true);
        return dispatch(setActions([]));
      }
      notify(`${actions.length} actions trouvées`, ` `, "success", false);
      return dispatch(setActions(actions));
    });
}

export async function fetchSessionActions(timestamp) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${STORAGE_URL}/action/getBySessionTimestamp`, { timestamp })
      .then((resp) => resp.data) // Transform the data into json
      .then(function (actions) {
        if (!actions) {
          return resolve([]);
        }
        return resolve(actions);
      });
  });
}

export async function setNoteSynced(noteId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${STORAGE_URL}/notes/${noteId}`, { syncedToDrive: true })
      .then((resp) => resp.data) // Transform the data into json
      .then(function (data) {
        return resolve(data)
      })
      .catch((e) => {
        return reject(e)
      })
  });
}
