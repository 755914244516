import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Action, ActionButton, ActionButtonsContainer } from "../styloActions/actions";
import { fetchSessionActions, fetchSessions } from "../../actions/storage-actions";
// import { fetchBlocks } from '../../actions/storage-actions';
import "../../css/editdocument.scopped.css";
import note1 from "../../notes/note1";
import { getSessions } from "../../reducers/storage-reducer";
import { useHandWritting } from "../useIsHandWritten";
import HandWriting from "./customExtensions/react-renderer";
import editor, { createBlock } from "./editor";
// import editor from "./editor";



const ActionsContainer = styled.div`
  position: relative;
  width: 20rem;
  height: 100%;
  margin-left: 0.5rem;
`

const NoteEditor = () => {
  const dispatch = useDispatch();
  const handWritting: any = useHandWritting();
  const { session_id } = useParams()
  const sessions = useSelector(getSessions);
  const navigate = useNavigate();
  const [actions, setActions] = useState([])

  useEffect(() => {
    if (!sessions) {
      fetchSessions(dispatch);
      return;
    }

    (async () => {
      const session = sessions.find((session: any) => session._id == session_id);

      if (!session) {
        return navigate('/');
      }

      // TODO! Absolutly ugly, need to change that
      const actions = await fetchSessionActions(new Date(session.timestamp).getTime().toString());
      console.log("BLOCKxx SESSION", session.elements)
      const block = createBlock(session, actions);
      console.log("BLOCKxx", editor, block)
      editor?.commands.setContent({
        type: "doc",
        content: [
          block
        ],
      })
      setActions(actions)
    })()
  }, [sessions])

  const repositionAction = () => {
    const tiptapeditor: HTMLElement | null = document.querySelector("#tiptapEditor");
    document.querySelectorAll("#noteEditor [data-action-id]").forEach((action: any) => {
      const id = action.getAttribute("data-action-id");
      const text: HTMLElement | null = document.querySelector(`[data-${id}]`)
      if (text && tiptapeditor) {
        action.style.marginTop = `max(${text?.offsetTop + tiptapeditor?.offsetTop}px, 20rem)`;
        action.style.transform = "translateY(-100%)"
      }
    })
  }

  editor.on('update', repositionAction)
  useLayoutEffect(repositionAction, [actions])

  return (
    <div id="noteEditor">
      <EditorContent id="tiptapEditor" editor={editor} />
      <ActionButtonsContainer>
        <ActionButton
          title={handWritting.active ? "SEE TEXT" : "SEE HANDWRITTING"}
          onClick={() => handWritting.setActive(!handWritting.active)}
          picture="/images/hide_modifications.svg"
        />
        {/* <ActionButton
          title="ADD ACTIONS"
          picture="/images/hide_modifications.svg"
        />
        <ActionButton
          title="HIDE MODIFICATIONS"
          picture="/images/hide_modifications.svg"
        /> */}
      </ActionButtonsContainer>
      <ActionsContainer>
        {actions.map(action => <Action action={action} />)}
      </ActionsContainer>
    </div>
  );
};

export default NoteEditor;
