import {
  SET_LOGIN,
} from '../actions/login-actions';

export default function loginReducer(state='', { type, payload }) {
  switch (type) {
    case SET_LOGIN:
    return {
      ...state,
      loggedIn: payload
    }
    default:
    return state;
  }
}

// selectors
export function getIsLoggedIn(state) {
  return state.loginReducer.loggedIn;
}
