const note3= {
    "elements": [
        {
            "_id": "6071cfd3357fff0f20985e86",
            "id": 180,
            "type": "line",
            "boundingBox": {
                "x": 21.1006451,
                "y": 12.8826876,
                "width": 2.52651978,
                "height": 5.62214565
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e87",
                "strokes": [
                    {
                        "x": [
                            22.2461662,
                            22.2355824,
                            22.2276459,
                            22.2197094,
                            22.2144165,
                            22.217062,
                            22.2064781,
                            22.2011871,
                            22.1958942,
                            22.1879578,
                            22.1826649,
                            22.1800213,
                            22.1800213,
                            22.1853123,
                            22.1853123,
                            22.2197094,
                            22.2699776,
                            22.3361244,
                            22.4128532,
                            22.434021,
                            22.4684162,
                            22.5001659,
                            22.5133934,
                            22.5372086,
                            22.5424976,
                            22.5424976,
                            22.5557289,
                            22.5874786,
                            22.5980625,
                            22.6218739,
                            22.6218739,
                            22.6245213,
                            22.6165829,
                            22.6271648,
                            22.61129,
                            22.6086445,
                            22.6033535,
                            22.6033535,
                            22.595417,
                            22.5927696,
                            22.5610199,
                            22.5477905,
                            22.545145,
                            22.5239773,
                            22.5213337,
                            22.5160408,
                            22.5001659,
                            22.4895821,
                            22.4816456,
                            22.4366665,
                            22.4287281,
                            22.4207897,
                            22.4049168,
                            22.3916855,
                            22.3467083,
                            22.2884979,
                            22.2646866,
                            22.2488117,
                            22.2461662,
                            22.2302895,
                            22.222353,
                            22.2197094,
                            22.2117691,
                            22.2038326,
                            22.1932487,
                            22.1906052,
                            22.1853123,
                            22.1403332,
                            22.1085815,
                            22.1032906,
                            22.1006451,
                            22.1085815,
                            22.1085815
                        ],
                        "y": [
                            13.9012079,
                            13.895916,
                            13.890625,
                            13.8879795,
                            13.8853331,
                            13.8853331,
                            13.8826876,
                            13.8879795,
                            13.890625,
                            13.8985615,
                            13.9091454,
                            13.9197292,
                            13.9250212,
                            13.9567709,
                            13.9805832,
                            14.0890617,
                            14.1922493,
                            14.3218956,
                            14.4912291,
                            14.5679579,
                            14.718771,
                            14.8087292,
                            14.8589993,
                            15.0283327,
                            15.0547915,
                            15.0786037,
                            15.1659164,
                            15.2902708,
                            15.3405409,
                            15.5998325,
                            15.7903328,
                            15.8485413,
                            15.930562,
                            15.9649582,
                            15.986124,
                            16.0893116,
                            16.1131248,
                            16.2057285,
                            16.2559986,
                            16.3327293,
                            16.4756031,
                            16.5338116,
                            16.5602703,
                            16.647583,
                            16.6925621,
                            16.7243118,
                            16.7878132,
                            16.8222084,
                            16.8592491,
                            16.9650841,
                            16.9783115,
                            17.0100632,
                            17.0391674,
                            17.0603333,
                            17.1555824,
                            17.2402496,
                            17.2772923,
                            17.314333,
                            17.3275623,
                            17.3619595,
                            17.3751869,
                            17.3831234,
                            17.3910637,
                            17.4042912,
                            17.4122276,
                            17.4175205,
                            17.420166,
                            17.4677906,
                            17.4968948,
                            17.5048332,
                            17.5021877,
                            17.4889584,
                            17.4889584
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            23,
                            30,
                            38,
                            45,
                            76,
                            83,
                            106,
                            113,
                            136,
                            143,
                            166,
                            173,
                            203,
                            226,
                            248,
                            278,
                            293,
                            323,
                            346,
                            361,
                            391,
                            398,
                            406,
                            428,
                            459,
                            474,
                            541,
                            586,
                            601,
                            624,
                            631,
                            639,
                            661,
                            669,
                            699,
                            714,
                            736,
                            774,
                            789,
                            796,
                            819,
                            834,
                            842,
                            850,
                            857,
                            865,
                            895,
                            902,
                            917,
                            932,
                            940,
                            962,
                            985,
                            1000,
                            1022,
                            1030,
                            1045,
                            1052,
                            1060,
                            1067,
                            1075,
                            1082,
                            1090,
                            1097,
                            1135,
                            1150,
                            1157,
                            1165,
                            1172,
                            1215
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e88"
                    }
                ],
                "x1": 22.4334431,
                "y1": 13.8796854,
                "x2": 22.481329,
                "y2": 17.4998322
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e89",
            "id": 181,
            "type": "line",
            "boundingBox": {
                "x": 21.4578323,
                "y": 19.3094158,
                "width": 2.02910423,
                "height": 2.12435532
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e8a",
                "strokes": [
                    {
                        "x": [
                            22.4869366,
                            22.4816456,
                            22.4763527,
                            22.4763527,
                            22.4763527,
                            22.4604778,
                            22.4578323,
                            22.4578323
                        ],
                        "y": [
                            20.3199997,
                            20.3120613,
                            20.3094158,
                            20.3226452,
                            20.3358746,
                            20.4152508,
                            20.4337711,
                            20.4337711
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            46,
                            53,
                            83,
                            91,
                            122
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e8b"
                    }
                ],
                "x1": 22.4811478,
                "y1": 20.3103561,
                "x2": 22.4569645,
                "y2": 20.4336014
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e8c",
            "id": 182,
            "type": "line",
            "boundingBox": {
                "x": 21.3811035,
                "y": 19.616333,
                "width": 2.01322937,
                "height": 2.06350136
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e8d",
                "strokes": [
                    {
                        "x": [
                            22.3943329,
                            22.3890419,
                            22.3811035,
                            22.3863945,
                            22.3863945,
                            22.3916855,
                            22.3890419,
                            22.3890419,
                            22.3916855,
                            22.3890419,
                            22.3890419
                        ],
                        "y": [
                            20.616333,
                            20.6322079,
                            20.6348534,
                            20.6401463,
                            20.6533737,
                            20.6745415,
                            20.6798344,
                            20.6745415,
                            20.6692505,
                            20.6613102,
                            20.6613102
                        ],
                        "t": [
                            0,
                            7,
                            15,
                            30,
                            45,
                            60,
                            67,
                            90,
                            105,
                            112,
                            141
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e8e"
                    }
                ],
                "x1": 22.3865089,
                "y1": 20.61689,
                "x2": 22.3909721,
                "y2": 20.679697
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e8f",
            "id": 183,
            "type": "line",
            "boundingBox": {
                "x": 21.7303543,
                "y": 19.2723751,
                "width": 2.01852036,
                "height": 2.18256187
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e90",
                "strokes": [
                    {
                        "x": [
                            22.7409363,
                            22.7409363,
                            22.7409363,
                            22.7462292,
                            22.7462292,
                            22.7488747,
                            22.7462292,
                            22.7435818,
                            22.7356453,
                            22.7329998,
                            22.7303543,
                            22.7303543
                        ],
                        "y": [
                            20.2750187,
                            20.2723751,
                            20.2750187,
                            20.2803116,
                            20.28825,
                            20.2988338,
                            20.3491039,
                            20.3808537,
                            20.4073105,
                            20.4443531,
                            20.454937,
                            20.454937
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            30,
                            38,
                            45,
                            68,
                            75,
                            83,
                            98,
                            105,
                            139
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e91"
                    }
                ],
                "x1": 22.7505741,
                "y1": 20.2733002,
                "x2": 22.7331314,
                "y2": 20.455204
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e92",
            "id": 184,
            "type": "line",
            "boundingBox": {
                "x": 21.6589165,
                "y": 19.5792904,
                "width": 2.01322937,
                "height": 2.08201981
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e93",
                "strokes": [
                    {
                        "x": [
                            22.6721458,
                            22.6695004,
                            22.6642094,
                            22.6642094,
                            22.661562,
                            22.661562,
                            22.6589165,
                            22.661562,
                            22.661562,
                            22.661562,
                            22.6642094,
                            22.666853,
                            22.666853
                        ],
                        "y": [
                            20.5792904,
                            20.5925217,
                            20.5978107,
                            20.6057491,
                            20.6136875,
                            20.6322079,
                            20.6401463,
                            20.6507301,
                            20.6613102,
                            20.6586666,
                            20.6560211,
                            20.6480827,
                            20.6480827
                        ],
                        "t": [
                            0,
                            7,
                            15,
                            22,
                            30,
                            45,
                            52,
                            60,
                            75,
                            98,
                            105,
                            113,
                            141
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e94"
                    }
                ],
                "x1": 22.6639824,
                "y1": 20.5792904,
                "x2": 22.6639824,
                "y2": 20.6613102
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e95",
            "id": 185,
            "type": "line",
            "boundingBox": {
                "x": 21.928791,
                "y": 19.2988338,
                "width": 2.05291748,
                "height": 2.21960449
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e96",
                "strokes": [
                    {
                        "x": [
                            22.9314365,
                            22.928791,
                            22.928791,
                            22.9314365,
                            22.9367294,
                            22.9473133,
                            22.9737701,
                            22.9817085,
                            22.9764156,
                            22.9578934,
                            22.9499569,
                            22.939373,
                            22.934082,
                            22.939373,
                            22.9420204,
                            22.9420204,
                            22.9420204,
                            22.9420204
                        ],
                        "y": [
                            20.3385201,
                            20.3305817,
                            20.3252907,
                            20.3199997,
                            20.3147068,
                            20.3094158,
                            20.3014793,
                            20.2988338,
                            20.3332291,
                            20.394083,
                            20.4258327,
                            20.4602299,
                            20.4972706,
                            20.510498,
                            20.5157909,
                            20.5184383,
                            20.5184383,
                            20.5184383
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            23,
                            30,
                            38,
                            53,
                            68,
                            98,
                            105,
                            113,
                            120,
                            135,
                            143,
                            158,
                            165,
                            173,
                            212
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e97"
                    }
                ],
                "x1": 22.9652462,
                "y1": 20.2966976,
                "x2": 22.936573,
                "y2": 20.5177326
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e98",
            "id": 186,
            "type": "line",
            "boundingBox": {
                "x": 21.8864574,
                "y": 19.6851254,
                "width": 2.01587486,
                "height": 2.08201981
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e99",
                "strokes": [
                    {
                        "x": [
                            22.9023323,
                            22.8996868,
                            22.8970413,
                            22.8943939,
                            22.8891048,
                            22.8891048,
                            22.8864574,
                            22.8864574,
                            22.8891048,
                            22.8891048
                        ],
                        "y": [
                            20.6851254,
                            20.7009983,
                            20.7142296,
                            20.7327499,
                            20.7512703,
                            20.7671452,
                            20.7618542,
                            20.7565613,
                            20.7486248,
                            20.7486248
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            23,
                            38,
                            53,
                            83,
                            90,
                            98,
                            128
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e9a"
                    }
                ],
                "x1": 22.9018459,
                "y1": 20.6850395,
                "x2": 22.8874321,
                "y2": 20.7668514
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e9b",
            "id": 187,
            "type": "line",
            "boundingBox": {
                "x": 16.0206451,
                "y": 20.00527,
                "width": 7.3922081,
                "height": 2.20108414
            },
            "line": {
                "_id": "6071cfd3357fff0f20985e9c",
                "strokes": [
                    {
                        "x": [
                            17.0206451,
                            17.0206451,
                            17.0285835,
                            17.0418129,
                            17.0497494,
                            17.0629787,
                            17.0814991,
                            17.0947285,
                            17.1053123,
                            17.1291237,
                            17.1555824,
                            17.4069366,
                            17.5789165,
                            17.7773552,
                            18.6663551,
                            18.8965416,
                            19.0103111,
                            19.5950413,
                            20.6374989,
                            21.0184994,
                            22.3043747,
                            22.328186,
                            22.3334789,
                            22.3440609,
                            22.3625813,
                            22.4128532,
                            22.4049168,
                            22.3863945,
                            22.3572903,
                            22.3572903
                        ],
                        "y": [
                            21.0184994,
                            21.0132065,
                            21.0079174,
                            21.00527,
                            21.00527,
                            21.0105629,
                            21.0211449,
                            21.0290833,
                            21.0317287,
                            21.0449581,
                            21.0502491,
                            21.0370216,
                            21.0264378,
                            21.0184994,
                            21.0687695,
                            21.0978737,
                            21.1111031,
                            21.1640205,
                            21.1746025,
                            21.1984177,
                            21.2063541,
                            21.2063541,
                            21.2037067,
                            21.2010612,
                            21.1904774,
                            21.1746025,
                            21.1746025,
                            21.1746025,
                            21.1798954,
                            21.1798954
                        ],
                        "t": [
                            0,
                            8,
                            15,
                            23,
                            30,
                            45,
                            60,
                            68,
                            75,
                            90,
                            98,
                            135,
                            150,
                            165,
                            225,
                            232,
                            240,
                            277,
                            353,
                            390,
                            503,
                            525,
                            585,
                            593,
                            600,
                            623,
                            660,
                            675,
                            683,
                            725
                        ],
                        "p": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "i": [],
                        "o": [],
                        "_id": "6071cfd3357fff0f20985e9d"
                    }
                ],
                "x1": 17.0203476,
                "y1": 21.0205593,
                "x2": 22.4102612,
                "y2": 21.2386608
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985e9e",
            "id": 15,
            "type": "text",
            "boundingBox": {
                "x": 15.8301449,
                "y": 13.5732498,
                "width": 6.9609375,
                "height": 3.91558266
            },
            "text": {
                "_id": "6071cfd3357fff0f20985e9f",
                "words": [
                    {
                        "candidates": [
                            "ravarianais",
                            "mvariciais",
                            "mvariaais",
                            "avancerais",
                            "mvariarais"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    16.9015827,
                                    16.9042282,
                                    16.9121666,
                                    16.9201031,
                                    16.9227486,
                                    16.9280415,
                                    16.930687,
                                    16.930687,
                                    16.9359798,
                                    16.9412708,
                                    16.9386234,
                                    16.9412708,
                                    16.9518528,
                                    16.9545002,
                                    16.9597912,
                                    16.9597912,
                                    16.9597912,
                                    16.9571438,
                                    16.9492073,
                                    16.9465637,
                                    16.9333324,
                                    16.9333324,
                                    16.9359798,
                                    16.9465637,
                                    16.9545002,
                                    16.9703751,
                                    16.9941883,
                                    17.0576878,
                                    17.1106033,
                                    17.1582279,
                                    17.1820412,
                                    17.2137909,
                                    17.2296677,
                                    17.2270203,
                                    17.2164364,
                                    17.1846867,
                                    17.1635208,
                                    17.1423531,
                                    17.1158962,
                                    17.092083,
                                    17.0629787,
                                    17.0444565,
                                    17.0338745,
                                    17.031229,
                                    17.0576878,
                                    17.0973759,
                                    17.1370621,
                                    17.1767483,
                                    17.2084999,
                                    17.2111454,
                                    17.2084999,
                                    17.2084999,
                                    17.203207,
                                    17.203207
                                ],
                                "y": [
                                    14.8166666,
                                    14.8060827,
                                    14.7981453,
                                    14.7928534,
                                    14.7902079,
                                    14.7902079,
                                    14.8034372,
                                    14.8140211,
                                    14.8457708,
                                    14.9463129,
                                    15.0415621,
                                    15.1103535,
                                    15.2373533,
                                    15.2558746,
                                    15.2664585,
                                    15.2717495,
                                    15.2638121,
                                    15.2558746,
                                    15.2267704,
                                    15.1685629,
                                    15.0494995,
                                    14.8589993,
                                    14.8404789,
                                    14.7769785,
                                    14.7558126,
                                    14.7267075,
                                    14.6949577,
                                    14.6393957,
                                    14.6102915,
                                    14.6023531,
                                    14.6049995,
                                    14.6605625,
                                    14.7425833,
                                    14.7928534,
                                    14.835187,
                                    14.9013329,
                                    14.9277906,
                                    14.9463129,
                                    14.9621868,
                                    14.9701252,
                                    14.9674788,
                                    14.9621868,
                                    14.9595413,
                                    14.9542494,
                                    14.9674788,
                                    14.9939365,
                                    15.0336246,
                                    15.0918331,
                                    15.1764994,
                                    15.1923742,
                                    15.1976662,
                                    15.1923742,
                                    15.1791449,
                                    15.1791449
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    45,
                                    68,
                                    76,
                                    91,
                                    113,
                                    136,
                                    151,
                                    188,
                                    196,
                                    203,
                                    211,
                                    271,
                                    286,
                                    301,
                                    316,
                                    338,
                                    376,
                                    383,
                                    413,
                                    421,
                                    428,
                                    436,
                                    451,
                                    459,
                                    466,
                                    474,
                                    496,
                                    511,
                                    519,
                                    526,
                                    541,
                                    549,
                                    556,
                                    564,
                                    571,
                                    578,
                                    586,
                                    593,
                                    601,
                                    646,
                                    661,
                                    676,
                                    691,
                                    721,
                                    728,
                                    736,
                                    773,
                                    788,
                                    832
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea1"
                            },
                            {
                                "x": [
                                    17.4254589,
                                    17.414875,
                                    17.409584,
                                    17.4122276,
                                    17.4333954,
                                    17.4175205,
                                    17.4254589,
                                    17.4598522,
                                    17.4783745,
                                    17.4836674,
                                    17.48102,
                                    17.486311,
                                    17.4836674,
                                    17.486311,
                                    17.4783745,
                                    17.475729,
                                    17.4704361,
                                    17.4651451,
                                    17.4572086,
                                    17.4492702,
                                    17.4466248,
                                    17.4386883,
                                    17.4333954,
                                    17.4254589,
                                    17.420166,
                                    17.4175205,
                                    17.4281044,
                                    17.5339355,
                                    17.5762711,
                                    17.6080208,
                                    17.6794586,
                                    17.7270832,
                                    17.7350197,
                                    17.7350197,
                                    17.7323742,
                                    17.7270832,
                                    17.6953316,
                                    17.5683327,
                                    17.5392284,
                                    17.5259991,
                                    17.5180626,
                                    17.5048332,
                                    17.4916039,
                                    17.4836674,
                                    17.4783745,
                                    17.475729,
                                    17.4783745,
                                    17.4783745,
                                    17.4783745
                                ],
                                "y": [
                                    14.7558126,
                                    14.7399368,
                                    14.7267075,
                                    14.7240629,
                                    14.7319994,
                                    14.7399368,
                                    14.7558126,
                                    14.8669367,
                                    14.9621868,
                                    15.0733128,
                                    15.1235828,
                                    15.1341667,
                                    15.1526871,
                                    15.157979,
                                    15.0865412,
                                    14.9939365,
                                    14.9568949,
                                    14.9277906,
                                    14.9039793,
                                    14.8589993,
                                    14.8378334,
                                    14.8166666,
                                    14.7954988,
                                    14.7716875,
                                    14.7425833,
                                    14.7372913,
                                    14.7425833,
                                    14.7716875,
                                    14.7849169,
                                    14.8087292,
                                    14.9039793,
                                    15.0336246,
                                    15.0547915,
                                    15.0706663,
                                    15.0838947,
                                    15.0971251,
                                    15.1262283,
                                    15.1897287,
                                    15.1976662,
                                    15.1976662,
                                    15.1950207,
                                    15.1870832,
                                    15.1791449,
                                    15.1685629,
                                    15.1526871,
                                    15.1473951,
                                    15.1473951,
                                    15.1421032,
                                    15.1421032
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    45,
                                    52,
                                    60,
                                    82,
                                    97,
                                    120,
                                    135,
                                    142,
                                    150,
                                    157,
                                    203,
                                    218,
                                    225,
                                    233,
                                    240,
                                    255,
                                    263,
                                    270,
                                    278,
                                    285,
                                    300,
                                    315,
                                    353,
                                    383,
                                    390,
                                    398,
                                    420,
                                    450,
                                    458,
                                    465,
                                    473,
                                    480,
                                    495,
                                    525,
                                    533,
                                    540,
                                    548,
                                    563,
                                    570,
                                    578,
                                    593,
                                    601,
                                    608,
                                    616,
                                    650
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea2"
                            },
                            {
                                "x": [
                                    17.7799988,
                                    17.7720623,
                                    17.7694168,
                                    17.7694168,
                                    17.7720623,
                                    17.7747078,
                                    17.7773552,
                                    17.7773552,
                                    17.7826443,
                                    17.8011665,
                                    17.8382072,
                                    17.8514366,
                                    17.8964157,
                                    17.9043541,
                                    17.9069996,
                                    17.9334583,
                                    17.9519787,
                                    17.9890194,
                                    18.0419369,
                                    18.0472279,
                                    18.0710411,
                                    18.0789795,
                                    18.0763321,
                                    18.0763321,
                                    18.0763321
                                ],
                                "y": [
                                    14.8457708,
                                    14.835187,
                                    14.8246031,
                                    14.8166666,
                                    14.8113747,
                                    14.8060827,
                                    14.8113747,
                                    14.8193121,
                                    14.8378334,
                                    14.9939365,
                                    15.1182909,
                                    15.1447496,
                                    15.2056036,
                                    15.2082491,
                                    15.2029581,
                                    15.1315203,
                                    15.0786037,
                                    14.9330826,
                                    14.7875624,
                                    14.7637491,
                                    14.7214165,
                                    14.7108326,
                                    14.7081871,
                                    14.7108326,
                                    14.7108326
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    45,
                                    60,
                                    68,
                                    75,
                                    105,
                                    128,
                                    135,
                                    158,
                                    165,
                                    181,
                                    203,
                                    211,
                                    226,
                                    256,
                                    263,
                                    278,
                                    286,
                                    301,
                                    308,
                                    340
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea3"
                            },
                            {
                                "x": [
                                    18.8251038,
                                    18.8277512,
                                    18.8383312,
                                    18.8436241,
                                    18.854208,
                                    18.8462715,
                                    18.8515625,
                                    18.8489151,
                                    18.8515625,
                                    18.8515625,
                                    18.8515625,
                                    18.8515625,
                                    18.8515625
                                ],
                                "y": [
                                    14.7875624,
                                    14.7769785,
                                    14.7690411,
                                    14.7663956,
                                    14.7902079,
                                    15.0442085,
                                    15.1368122,
                                    15.1606245,
                                    15.1764994,
                                    15.1870832,
                                    15.1817913,
                                    15.1712084,
                                    15.1712084
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    53,
                                    98,
                                    113,
                                    121,
                                    128,
                                    143,
                                    151,
                                    158,
                                    191
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea4"
                            },
                            {
                                "x": [
                                    18.9759159,
                                    18.9917908,
                                    19.0103111,
                                    19.0394154,
                                    19.0579357,
                                    19.0817509,
                                    19.1346664,
                                    19.1637707,
                                    19.1955204,
                                    19.1981659,
                                    19.177,
                                    19.1399574,
                                    19.0552902,
                                    19.0394154,
                                    19.0341244,
                                    19.0341244
                                ],
                                "y": [
                                    14.7425833,
                                    14.7346449,
                                    14.7319994,
                                    14.729353,
                                    14.7346449,
                                    14.7452288,
                                    14.7822704,
                                    14.8113747,
                                    14.8960409,
                                    14.9489574,
                                    15.0627289,
                                    15.163271,
                                    15.2691031,
                                    15.2823324,
                                    15.2849789,
                                    15.2849789
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    52,
                                    60,
                                    75,
                                    82,
                                    97,
                                    112,
                                    135,
                                    142,
                                    150,
                                    182
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea5"
                            },
                            {
                                "x": [
                                    19.0182495,
                                    19.015604,
                                    19.015604,
                                    19.0235424,
                                    19.0314789,
                                    19.0394154,
                                    19.0896873,
                                    19.1849384,
                                    19.2166862,
                                    19.2457905,
                                    19.2457905
                                ],
                                "y": [
                                    15.0336246,
                                    15.0203953,
                                    15.0151033,
                                    15.0177488,
                                    15.0203953,
                                    15.0256872,
                                    15.0309792,
                                    15.0256872,
                                    15.0151033,
                                    15.0018749,
                                    15.0018749
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    30,
                                    38,
                                    45,
                                    60,
                                    75,
                                    83,
                                    90,
                                    122
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea6"
                            },
                            {
                                "x": [
                                    19.3886662,
                                    19.3780823,
                                    19.3780823,
                                    19.3833752,
                                    19.3833752,
                                    19.3886662,
                                    19.393959,
                                    19.4177704,
                                    19.4283543,
                                    19.4283543,
                                    19.4309998,
                                    19.4336452,
                                    19.4336452,
                                    19.4362907,
                                    19.4389381,
                                    19.4362907,
                                    19.4336452,
                                    19.4336452,
                                    19.4230633,
                                    19.4204159,
                                    19.4204159,
                                    19.4204159,
                                    19.4204159,
                                    19.4204159,
                                    19.4204159,
                                    19.4204159,
                                    19.4177704,
                                    19.4283543,
                                    19.4309998,
                                    19.4309998,
                                    19.4415836,
                                    19.4521656,
                                    19.4839153,
                                    19.5341873,
                                    19.5606461,
                                    19.5738754,
                                    19.5844574,
                                    19.5818119,
                                    19.5818119,
                                    19.5818119,
                                    19.5844574,
                                    19.5950413,
                                    19.6082706,
                                    19.6082706
                                ],
                                "y": [
                                    14.7875624,
                                    14.7902079,
                                    14.7902079,
                                    14.8034372,
                                    14.8113747,
                                    14.8219576,
                                    14.8378334,
                                    14.885457,
                                    14.941021,
                                    15.052145,
                                    15.0918331,
                                    15.2161865,
                                    15.2294168,
                                    15.2373533,
                                    15.2320623,
                                    15.2267704,
                                    15.2161865,
                                    15.2029581,
                                    15.1791449,
                                    15.1712084,
                                    15.1712084,
                                    15.1738529,
                                    15.1738529,
                                    15.163271,
                                    15.157979,
                                    15.1526871,
                                    15.1421032,
                                    15.1156454,
                                    15.0971251,
                                    15.0759583,
                                    15.0389166,
                                    15.0203953,
                                    14.9859991,
                                    14.9595413,
                                    14.9516029,
                                    14.9516029,
                                    14.9621868,
                                    14.9621868,
                                    14.9621868,
                                    14.9568949,
                                    14.9516029,
                                    14.9436665,
                                    14.9330826,
                                    14.9330826
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    30,
                                    37,
                                    45,
                                    52,
                                    67,
                                    82,
                                    105,
                                    112,
                                    150,
                                    157,
                                    165,
                                    203,
                                    210,
                                    218,
                                    225,
                                    248,
                                    263,
                                    293,
                                    300,
                                    308,
                                    405,
                                    420,
                                    435,
                                    443,
                                    458,
                                    465,
                                    473,
                                    488,
                                    495,
                                    510,
                                    525,
                                    533,
                                    540,
                                    571,
                                    601,
                                    608,
                                    616,
                                    623,
                                    631,
                                    638,
                                    689
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea7"
                            },
                            {
                                "x": [
                                    19.6506023,
                                    19.6506023,
                                    19.6479588,
                                    19.6479588,
                                    19.6426659,
                                    19.6400204,
                                    19.6347275,
                                    19.6347275,
                                    19.6347275,
                                    19.632082,
                                    19.626791,
                                    19.5897503,
                                    19.6003342,
                                    19.5897503,
                                    19.5871029,
                                    19.5871029,
                                    19.5871029
                                ],
                                "y": [
                                    14.8775206,
                                    14.8669367,
                                    14.8537073,
                                    14.8457708,
                                    14.835187,
                                    14.8246031,
                                    14.8140211,
                                    14.8060827,
                                    14.8166666,
                                    14.829895,
                                    14.8960409,
                                    15.157979,
                                    15.1791449,
                                    15.1844368,
                                    15.1791449,
                                    15.1712084,
                                    15.1712084
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    45,
                                    60,
                                    105,
                                    112,
                                    127,
                                    172,
                                    180,
                                    187,
                                    217,
                                    225,
                                    257
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea8"
                            },
                            {
                                "x": [
                                    20.3199997,
                                    20.3252907,
                                    20.3226452,
                                    20.3199997,
                                    20.3067703,
                                    20.2908955,
                                    20.2697296,
                                    20.2459145,
                                    20.2221031,
                                    20.2035828,
                                    20.1665421,
                                    20.1744785,
                                    20.1877079,
                                    20.2062302,
                                    20.2273941,
                                    20.2617912,
                                    20.2988338,
                                    20.3358746,
                                    20.3914375,
                                    20.4126034,
                                    20.4205418,
                                    20.4178944,
                                    20.4178944,
                                    20.4231873,
                                    20.4284782,
                                    20.4231873,
                                    20.4258327,
                                    20.4231873,
                                    20.4284782,
                                    20.4311256,
                                    20.4390621,
                                    20.4390621
                                ],
                                "y": [
                                    14.991291,
                                    14.9833536,
                                    14.9754162,
                                    14.9674788,
                                    14.9595413,
                                    14.9568949,
                                    14.9595413,
                                    14.9674788,
                                    14.9807072,
                                    15.0018749,
                                    15.0759583,
                                    15.1473951,
                                    15.1791449,
                                    15.2056036,
                                    15.2214785,
                                    15.2267704,
                                    15.2267704,
                                    15.2161865,
                                    15.1712084,
                                    15.1421032,
                                    15.0865412,
                                    15.0283327,
                                    14.9833536,
                                    14.996583,
                                    15.0865412,
                                    15.1235828,
                                    15.1526871,
                                    15.1738529,
                                    15.1844368,
                                    15.1870832,
                                    15.1791449,
                                    15.1791449
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    31,
                                    38,
                                    46,
                                    53,
                                    61,
                                    68,
                                    83,
                                    98,
                                    106,
                                    113,
                                    121,
                                    128,
                                    136,
                                    143,
                                    158,
                                    166,
                                    181,
                                    196,
                                    211,
                                    241,
                                    263,
                                    271,
                                    278,
                                    286,
                                    293,
                                    301,
                                    308,
                                    336
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ea9"
                            },
                            {
                                "x": [
                                    20.3596859,
                                    20.3491039,
                                    20.3491039,
                                    20.3543949,
                                    20.3649788,
                                    20.3782063,
                                    20.3782063
                                ],
                                "y": [
                                    14.5732498,
                                    14.5758953,
                                    14.5811872,
                                    14.612937,
                                    14.7161245,
                                    14.7531662,
                                    14.7531662
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    31,
                                    53,
                                    61,
                                    92
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eaa"
                            },
                            {
                                "x": [
                                    20.7195187,
                                    20.7221661,
                                    20.727457,
                                    20.7301025,
                                    20.7353954,
                                    20.7380409,
                                    20.7353954,
                                    20.7486248,
                                    20.7512703,
                                    20.7565613,
                                    20.7565613,
                                    20.7592068,
                                    20.7512703,
                                    20.7380409,
                                    20.7433338,
                                    20.7592068,
                                    20.788311,
                                    20.8597488,
                                    20.899437,
                                    20.9258938,
                                    20.9523544,
                                    20.9682293,
                                    20.9788113,
                                    20.9735203,
                                    20.954998,
                                    20.9311867,
                                    20.8729782,
                                    20.838583,
                                    20.8253536,
                                    20.8200626,
                                    20.8200626
                                ],
                                "y": [
                                    14.7716875,
                                    14.7663956,
                                    14.7637491,
                                    14.7663956,
                                    14.7769785,
                                    14.8087292,
                                    15.1659164,
                                    15.2532291,
                                    15.2717495,
                                    15.2770414,
                                    15.2717495,
                                    15.2585201,
                                    15.2294168,
                                    15.1182909,
                                    14.774333,
                                    14.7108326,
                                    14.6658535,
                                    14.607645,
                                    14.6049995,
                                    14.6102915,
                                    14.6288128,
                                    14.6526251,
                                    14.6843748,
                                    14.7637491,
                                    14.8060827,
                                    14.8431244,
                                    14.8933954,
                                    14.9092703,
                                    14.9066248,
                                    14.9013329,
                                    14.9013329
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    38,
                                    53,
                                    98,
                                    113,
                                    120,
                                    128,
                                    143,
                                    150,
                                    158,
                                    173,
                                    203,
                                    211,
                                    218,
                                    233,
                                    241,
                                    248,
                                    256,
                                    263,
                                    271,
                                    286,
                                    293,
                                    301,
                                    316,
                                    331,
                                    338,
                                    346,
                                    378
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eab"
                            },
                            {
                                "x": [
                                    21.0396652,
                                    21.0370216,
                                    21.0370216,
                                    21.0396652,
                                    21.0396652,
                                    21.0396652,
                                    21.0396652,
                                    21.0317287,
                                    21.0026245,
                                    20.9523544,
                                    20.9179573,
                                    20.8914986,
                                    20.8756256,
                                    20.8597488,
                                    20.8623943,
                                    20.8729782,
                                    20.8888531,
                                    20.9020824,
                                    20.9232502,
                                    20.9470615,
                                    20.9655819,
                                    20.9973335,
                                    21.0132065,
                                    21.0264378,
                                    21.0396652,
                                    21.0449581,
                                    21.0449581,
                                    21.0449581,
                                    21.0423107,
                                    21.0423107,
                                    21.0449581,
                                    21.055542,
                                    21.0714169,
                                    21.1084576,
                                    21.1296253,
                                    21.1534367,
                                    21.1772499,
                                    21.2672062,
                                    21.2989578,
                                    21.3121872,
                                    21.3227692,
                                    21.3307076,
                                    21.3412914,
                                    21.3439369,
                                    21.3465824,
                                    21.3545208,
                                    21.3571663,
                                    21.3624573,
                                    21.3624573,
                                    21.3651028,
                                    21.3756866,
                                    21.383625,
                                    21.394207,
                                    21.4047909,
                                    21.4153748,
                                    21.455061,
                                    21.4735832,
                                    21.4973946,
                                    21.5238533,
                                    21.5291462,
                                    21.5317898,
                                    21.5291462,
                                    21.5264988,
                                    21.5238533,
                                    21.5238533,
                                    21.5344372,
                                    21.5450211,
                                    21.550312,
                                    21.555603,
                                    21.605875,
                                    21.6482067,
                                    21.7143536,
                                    21.7434578,
                                    21.7566872,
                                    21.7752094,
                                    21.7831459,
                                    21.7910824,
                                    21.7884369,
                                    21.7804985,
                                    21.7672691,
                                    21.7540417,
                                    21.7355213,
                                    21.7117062,
                                    21.6878948,
                                    21.661438,
                                    21.661438
                                ],
                                "y": [
                                    14.9330826,
                                    14.9383745,
                                    14.9436665,
                                    14.9463129,
                                    14.9516029,
                                    14.9542494,
                                    14.9621868,
                                    14.9595413,
                                    14.9754162,
                                    15.0177488,
                                    15.0442085,
                                    15.0759583,
                                    15.107708,
                                    15.1844368,
                                    15.2082491,
                                    15.2347088,
                                    15.2532291,
                                    15.2638121,
                                    15.2664585,
                                    15.2638121,
                                    15.2558746,
                                    15.2241249,
                                    15.2029581,
                                    15.1764994,
                                    15.102417,
                                    15.0786037,
                                    15.0733128,
                                    15.0812492,
                                    15.0891867,
                                    15.1500406,
                                    15.1659164,
                                    15.1817913,
                                    15.1976662,
                                    15.213541,
                                    15.213541,
                                    15.2082491,
                                    15.1950207,
                                    15.0865412,
                                    15.0415621,
                                    15.0283327,
                                    15.0230408,
                                    15.0203953,
                                    15.0256872,
                                    15.0309792,
                                    15.0389166,
                                    15.052145,
                                    15.0680208,
                                    15.1712084,
                                    15.1897287,
                                    15.2029581,
                                    15.218833,
                                    15.2241249,
                                    15.2241249,
                                    15.2214785,
                                    15.218833,
                                    15.1950207,
                                    15.1791449,
                                    15.1421032,
                                    15.0627289,
                                    15.0547915,
                                    15.0468531,
                                    15.0415621,
                                    15.0680208,
                                    15.0838947,
                                    15.1182909,
                                    15.1526871,
                                    15.1738529,
                                    15.1764994,
                                    15.1764994,
                                    15.1315203,
                                    15.0997705,
                                    15.0442085,
                                    15.0309792,
                                    15.0283327,
                                    15.0309792,
                                    15.0362711,
                                    15.0627289,
                                    15.1315203,
                                    15.1500406,
                                    15.1685629,
                                    15.1791449,
                                    15.1870832,
                                    15.1870832,
                                    15.1817913,
                                    15.1659164,
                                    15.1659164
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    45,
                                    52,
                                    67,
                                    82,
                                    90,
                                    97,
                                    105,
                                    120,
                                    128,
                                    135,
                                    143,
                                    150,
                                    158,
                                    165,
                                    173,
                                    188,
                                    195,
                                    203,
                                    225,
                                    240,
                                    248,
                                    278,
                                    285,
                                    308,
                                    315,
                                    323,
                                    330,
                                    345,
                                    353,
                                    360,
                                    368,
                                    405,
                                    420,
                                    428,
                                    435,
                                    443,
                                    458,
                                    465,
                                    473,
                                    480,
                                    488,
                                    518,
                                    526,
                                    533,
                                    548,
                                    556,
                                    563,
                                    571,
                                    578,
                                    593,
                                    601,
                                    616,
                                    646,
                                    653,
                                    661,
                                    676,
                                    781,
                                    788,
                                    803,
                                    818,
                                    825,
                                    833,
                                    848,
                                    878,
                                    893,
                                    916,
                                    931,
                                    938,
                                    946,
                                    953,
                                    968,
                                    991,
                                    998,
                                    1006,
                                    1013,
                                    1021,
                                    1028,
                                    1036,
                                    1043,
                                    1085
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eac"
                            },
                            {
                                "x": [
                                    21.5450211,
                                    21.5238533,
                                    21.5079784,
                                    21.500042,
                                    21.4947491,
                                    21.4868126,
                                    21.4868126
                                ],
                                "y": [
                                    14.885457,
                                    14.8589993,
                                    14.8404789,
                                    14.8325415,
                                    14.8325415,
                                    14.8510628,
                                    14.8510628
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    38,
                                    68,
                                    95
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ead"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ea0",
                        "label": "ravarianais"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985eae",
                        "label": "\n"
                    },
                    {
                        "candidates": [
                            "a",
                            "au",
                            "an",
                            "ae",
                            "ai"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    17.0285835,
                                    17.0153522,
                                    17.0021248,
                                    16.9836044,
                                    16.9624367,
                                    16.9174576,
                                    16.8962917,
                                    16.8513107,
                                    16.8301449,
                                    16.8301449,
                                    16.8354378,
                                    16.8486671,
                                    16.8671875,
                                    16.8909988,
                                    16.9201031,
                                    16.9492073,
                                    16.986248,
                                    17.0814991,
                                    17.1502914,
                                    17.1767483,
                                    17.1661663,
                                    17.1502914,
                                    17.1344166,
                                    17.1211872,
                                    17.1106033,
                                    17.086792,
                                    17.0074158,
                                    16.9941883,
                                    16.9915409,
                                    17.0127087,
                                    17.0259361,
                                    17.0418129,
                                    17.0550404,
                                    17.0629787,
                                    17.0709152,
                                    17.1026669,
                                    17.203207,
                                    17.248188,
                                    17.2561245,
                                    17.269352,
                                    17.2825832,
                                    17.2931671,
                                    17.3063965,
                                    17.3196239,
                                    17.3381443,
                                    17.3513756,
                                    17.364603,
                                    17.3831234,
                                    17.420166,
                                    17.4360409,
                                    17.4545631,
                                    17.4519157,
                                    17.4439793,
                                    17.4281044,
                                    17.414875,
                                    17.4069366,
                                    17.3990002,
                                    17.3778324,
                                    17.3725414,
                                    17.3831234,
                                    17.3963528,
                                    17.414875,
                                    17.4519157,
                                    17.5048332,
                                    17.5551033,
                                    17.5551033
                                ],
                                "y": [
                                    15.7532911,
                                    15.7532911,
                                    15.7559366,
                                    15.763875,
                                    15.7744579,
                                    15.8114986,
                                    15.8326664,
                                    15.9014578,
                                    15.9755411,
                                    16.0072918,
                                    16.036396,
                                    16.0575619,
                                    16.0760822,
                                    16.0893116,
                                    16.0919571,
                                    16.0866661,
                                    16.0681458,
                                    15.9834785,
                                    15.8935204,
                                    15.8062086,
                                    15.6315832,
                                    15.6051245,
                                    15.5892487,
                                    15.5866041,
                                    15.5866041,
                                    15.6157084,
                                    15.7850409,
                                    15.8538322,
                                    15.9173326,
                                    16.0284576,
                                    16.0602074,
                                    16.0760822,
                                    16.0813751,
                                    16.0787296,
                                    16.0734367,
                                    16.0390415,
                                    15.880291,
                                    15.8300209,
                                    15.8300209,
                                    15.8511868,
                                    15.8776445,
                                    15.9120407,
                                    15.986124,
                                    16.0099373,
                                    16.0258121,
                                    16.0337486,
                                    16.0337486,
                                    16.0231667,
                                    15.9808331,
                                    15.9570208,
                                    15.8935204,
                                    15.8644161,
                                    15.8353119,
                                    15.8167906,
                                    15.8114986,
                                    15.8141451,
                                    15.8220825,
                                    15.8829365,
                                    16.0019989,
                                    16.0284576,
                                    16.0443325,
                                    16.0549164,
                                    16.0575619,
                                    16.0443325,
                                    16.0205193,
                                    16.0205193
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    38,
                                    45,
                                    60,
                                    75,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    150,
                                    165,
                                    180,
                                    211,
                                    218,
                                    226,
                                    233,
                                    241,
                                    256,
                                    278,
                                    286,
                                    293,
                                    308,
                                    316,
                                    323,
                                    331,
                                    338,
                                    346,
                                    361,
                                    391,
                                    406,
                                    413,
                                    428,
                                    436,
                                    443,
                                    458,
                                    466,
                                    473,
                                    481,
                                    488,
                                    496,
                                    511,
                                    518,
                                    533,
                                    541,
                                    548,
                                    556,
                                    563,
                                    571,
                                    578,
                                    593,
                                    616,
                                    624,
                                    631,
                                    639,
                                    646,
                                    654,
                                    661,
                                    704
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb0"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985eaf",
                        "label": "a"
                    },
                    {
                        "candidates": [
                            ",",
                            "}"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    17.592144,
                                    17.5683327,
                                    17.5445194,
                                    17.531292,
                                    17.5286446,
                                    17.5392284,
                                    17.6106663,
                                    17.637125,
                                    17.6556454,
                                    17.6715202,
                                    17.6873951,
                                    17.6900406,
                                    17.6847477,
                                    17.6715202,
                                    17.642416,
                                    17.581562,
                                    17.5868549,
                                    17.5974369,
                                    17.6397705,
                                    17.6556454,
                                    17.6662273,
                                    17.6768112,
                                    17.6821041,
                                    17.6847477,
                                    17.6715202,
                                    17.647707,
                                    17.6212482,
                                    17.5471668,
                                    17.5154152,
                                    17.430748,
                                    17.3804798,
                                    17.2958126,
                                    17.2958126,
                                    17.3037491,
                                    17.3381443,
                                    17.3381443
                                ],
                                "y": [
                                    15.8723545,
                                    15.8670626,
                                    15.8617706,
                                    15.8617706,
                                    15.8564787,
                                    15.8538322,
                                    15.8379583,
                                    15.8379583,
                                    15.8432503,
                                    15.8564787,
                                    15.880291,
                                    15.9067497,
                                    15.9490824,
                                    15.986124,
                                    16.036396,
                                    16.1078339,
                                    16.1078339,
                                    16.0998955,
                                    16.0840206,
                                    16.0840206,
                                    16.0893116,
                                    16.0998955,
                                    16.1157703,
                                    16.1422291,
                                    16.2216034,
                                    16.2745209,
                                    16.3195,
                                    16.3988743,
                                    16.4279785,
                                    16.4650211,
                                    16.480896,
                                    16.4888325,
                                    16.4835415,
                                    16.4782486,
                                    16.4650211,
                                    16.4650211
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    52,
                                    75,
                                    82,
                                    90,
                                    97,
                                    105,
                                    112,
                                    120,
                                    127,
                                    135,
                                    157,
                                    180,
                                    187,
                                    202,
                                    210,
                                    217,
                                    225,
                                    232,
                                    240,
                                    248,
                                    255,
                                    263,
                                    278,
                                    285,
                                    300,
                                    308,
                                    331,
                                    346,
                                    353,
                                    361,
                                    398
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb2"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985eb1",
                        "label": ","
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985eb3",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "reine",
                            "seme",
                            "ame",
                            "sema",
                            "me"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    18.1557083,
                                    18.1477699,
                                    18.1451244,
                                    18.1451244,
                                    18.1504154,
                                    18.1557083,
                                    18.1636448,
                                    18.1768742,
                                    18.2721233,
                                    18.3356247,
                                    18.359436,
                                    18.3700199,
                                    18.3726673,
                                    18.3832512,
                                    18.3938313,
                                    18.4255829,
                                    18.470562,
                                    18.4732075,
                                    18.4679165,
                                    18.4626236,
                                    18.4626236,
                                    18.4573326,
                                    18.4573326
                                ],
                                "y": [
                                    16.0178757,
                                    16.0311031,
                                    16.041687,
                                    16.0469799,
                                    16.0469799,
                                    16.0443325,
                                    16.036396,
                                    16.0178757,
                                    15.8167906,
                                    15.6262913,
                                    15.5654373,
                                    15.5522079,
                                    15.546916,
                                    15.5574989,
                                    15.5680828,
                                    15.6474581,
                                    15.9093952,
                                    16.0072918,
                                    16.036396,
                                    16.0469799,
                                    16.0469799,
                                    16.0390415,
                                    16.0390415
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    45,
                                    52,
                                    90,
                                    112,
                                    127,
                                    135,
                                    142,
                                    165,
                                    172,
                                    187,
                                    217,
                                    232,
                                    240,
                                    247,
                                    255,
                                    262,
                                    292
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb5"
                            },
                            {
                                "x": [
                                    18.2774162,
                                    18.2721233,
                                    18.2774162,
                                    18.2800617,
                                    18.2880001,
                                    18.3673744,
                                    18.4070625,
                                    18.4070625
                                ],
                                "y": [
                                    15.7585831,
                                    15.7585831,
                                    15.7585831,
                                    15.763875,
                                    15.769166,
                                    15.7903328,
                                    15.7982702,
                                    15.7982702
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    23,
                                    30,
                                    53,
                                    60,
                                    95
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb6"
                            },
                            {
                                "x": [
                                    18.5869789,
                                    18.5869789,
                                    18.5896244,
                                    18.5975628,
                                    18.6134357,
                                    18.6769352,
                                    18.7325001,
                                    18.7563114,
                                    18.7827702,
                                    18.803936,
                                    18.803936,
                                    18.7959995,
                                    18.788063,
                                    18.7774792,
                                    18.7721863,
                                    18.7616043,
                                    18.7192707,
                                    18.6769352,
                                    18.6795826,
                                    18.692812,
                                    18.7086868,
                                    18.7510204,
                                    18.7774792,
                                    18.7986469,
                                    18.8145199,
                                    18.8621464,
                                    18.8886032,
                                    18.9335823,
                                    18.960043,
                                    18.9626865,
                                    18.9573956,
                                    18.9521027,
                                    18.9468117,
                                    18.960043,
                                    18.9785633,
                                    18.9944363,
                                    19.0103111,
                                    19.0182495,
                                    19.076458,
                                    19.0976238,
                                    19.1161461,
                                    19.1214352,
                                    19.132019,
                                    19.1452503,
                                    19.1743546,
                                    19.187582,
                                    19.2008114,
                                    19.2061024,
                                    19.2113953,
                                    19.2140427,
                                    19.2140427,
                                    19.2034588,
                                    19.1902275,
                                    19.1849384,
                                    19.182291,
                                    19.1902275,
                                    19.1928749,
                                    19.2034588,
                                    19.2696037,
                                    19.2828312,
                                    19.2934151,
                                    19.3039989,
                                    19.3304577,
                                    19.348978,
                                    19.3701458,
                                    19.3913116,
                                    19.3966026,
                                    19.4071865,
                                    19.4124794,
                                    19.415123,
                                    19.470686,
                                    19.5579987,
                                    19.565937,
                                    19.565937,
                                    19.5632915,
                                    19.5579987,
                                    19.5500622,
                                    19.5421238,
                                    19.5288944,
                                    19.4759789,
                                    19.4627495,
                                    19.4548111,
                                    19.460104,
                                    19.470686,
                                    19.4892082,
                                    19.5183105,
                                    19.5527077,
                                    19.5897503,
                                    19.6188545,
                                    19.632082,
                                    19.6241455,
                                    19.6241455
                                ],
                                "y": [
                                    15.986124,
                                    15.9887705,
                                    15.991416,
                                    15.9940624,
                                    15.991416,
                                    15.9385004,
                                    15.8697081,
                                    15.8300209,
                                    15.7268324,
                                    15.5389786,
                                    15.5019369,
                                    15.4834166,
                                    15.4728327,
                                    15.4701872,
                                    15.4728327,
                                    15.4834166,
                                    15.5522079,
                                    15.6871452,
                                    15.8882284,
                                    15.9385004,
                                    15.9702501,
                                    16.0125828,
                                    16.0231667,
                                    16.0231667,
                                    16.0178757,
                                    15.9728947,
                                    15.9411449,
                                    15.8458948,
                                    15.7532911,
                                    15.718895,
                                    15.7294788,
                                    15.7427082,
                                    15.9041042,
                                    15.935854,
                                    15.9543743,
                                    15.9596663,
                                    15.9543743,
                                    15.9490824,
                                    15.8697081,
                                    15.824729,
                                    15.8009167,
                                    15.7903328,
                                    15.7771044,
                                    15.7665205,
                                    15.7532911,
                                    15.7506447,
                                    15.7506447,
                                    15.7559366,
                                    15.7665205,
                                    15.7850409,
                                    15.8088541,
                                    15.8644161,
                                    15.8935204,
                                    15.9252701,
                                    15.930562,
                                    15.9252701,
                                    15.9173326,
                                    15.8961668,
                                    15.7744579,
                                    15.7585831,
                                    15.7506447,
                                    15.7506447,
                                    15.7718124,
                                    15.7982702,
                                    15.8591242,
                                    15.9279165,
                                    15.9596663,
                                    15.9967079,
                                    15.9967079,
                                    15.991416,
                                    15.930562,
                                    15.8220825,
                                    15.8035622,
                                    15.7850409,
                                    15.7771044,
                                    15.769166,
                                    15.763875,
                                    15.763875,
                                    15.769166,
                                    15.8326664,
                                    15.8644161,
                                    15.9596663,
                                    15.9755411,
                                    15.9887705,
                                    15.9940624,
                                    15.9967079,
                                    15.9834785,
                                    15.9570208,
                                    15.9226246,
                                    15.8855829,
                                    15.8458948,
                                    15.8458948
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    30,
                                    60,
                                    82,
                                    97,
                                    105,
                                    120,
                                    142,
                                    150,
                                    157,
                                    165,
                                    172,
                                    180,
                                    187,
                                    202,
                                    217,
                                    240,
                                    247,
                                    255,
                                    270,
                                    278,
                                    285,
                                    293,
                                    308,
                                    315,
                                    330,
                                    345,
                                    360,
                                    398,
                                    405,
                                    450,
                                    465,
                                    480,
                                    495,
                                    510,
                                    518,
                                    548,
                                    563,
                                    578,
                                    585,
                                    593,
                                    600,
                                    615,
                                    623,
                                    638,
                                    645,
                                    653,
                                    660,
                                    668,
                                    683,
                                    691,
                                    706,
                                    713,
                                    736,
                                    743,
                                    751,
                                    781,
                                    788,
                                    796,
                                    803,
                                    818,
                                    826,
                                    841,
                                    856,
                                    863,
                                    878,
                                    893,
                                    901,
                                    923,
                                    946,
                                    953,
                                    961,
                                    968,
                                    976,
                                    983,
                                    991,
                                    998,
                                    1021,
                                    1028,
                                    1051,
                                    1059,
                                    1066,
                                    1074,
                                    1081,
                                    1089,
                                    1096,
                                    1104,
                                    1111,
                                    1119,
                                    1150
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb7"
                            },
                            {
                                "x": [
                                    18.9970837,
                                    18.9521027,
                                    18.9309387,
                                    18.9229984,
                                    18.9229984,
                                    18.9282913,
                                    18.9388752,
                                    18.9388752
                                ],
                                "y": [
                                    15.4569578,
                                    15.4648952,
                                    15.4728327,
                                    15.4807701,
                                    15.4887085,
                                    15.4992914,
                                    15.5045824,
                                    15.5045824
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    45,
                                    74
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eb8"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985eb4",
                        "label": "reine"
                    }
                ],
                "label": "ravarianais\na, reine"
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985eb9",
            "id": 18,
            "type": "text",
            "boundingBox": {
                "x": 21.9155617,
                "y": 13.8589993,
                "width": 4.30716705,
                "height": 2.58208275
            },
            "text": {
                "_id": "6071cfd3357fff0f20985eba",
                "words": [
                    {
                        "candidates": [
                            "@",
                            "a",
                            "•",
                            ".",
                            "⑥"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    23.3256664,
                                    23.3256664,
                                    23.3256664,
                                    23.3256664,
                                    23.3203735,
                                    23.3097916,
                                    23.2939167,
                                    23.2462921,
                                    23.1774998,
                                    23.1536865,
                                    23.1192894,
                                    23.1087074,
                                    23.1034164,
                                    23.1087074,
                                    23.1166458,
                                    23.1325207,
                                    23.156332,
                                    23.1774998,
                                    23.1960201,
                                    23.2198334,
                                    23.2409992,
                                    23.2701035,
                                    23.2912693,
                                    23.2939167,
                                    23.3018532,
                                    23.3018532,
                                    23.3045006,
                                    23.2992077,
                                    23.2912693,
                                    23.2859783,
                                    23.2780418,
                                    23.2780418,
                                    23.2753963,
                                    23.2753963,
                                    23.2859783,
                                    23.2859783,
                                    23.2965622,
                                    23.2965622,
                                    23.2992077,
                                    23.3045006,
                                    23.3124371,
                                    23.317728,
                                    23.3521252,
                                    23.4050407,
                                    23.4129772,
                                    23.4367924,
                                    23.4420815,
                                    23.4447289,
                                    23.4420815,
                                    23.4420815,
                                    23.4367924,
                                    23.4182701,
                                    23.4050407,
                                    23.3971043,
                                    23.362709,
                                    23.3521252,
                                    23.3203735,
                                    23.3097916,
                                    23.2621651,
                                    23.2462921,
                                    23.206604,
                                    23.1483955,
                                    23.0928326,
                                    23.0743122,
                                    23.045208,
                                    23.039917,
                                    23.0266857,
                                    22.9949379,
                                    22.989645,
                                    22.9817085,
                                    22.9764156,
                                    22.9367294,
                                    22.9261456,
                                    22.9208527,
                                    22.9155617,
                                    22.9155617,
                                    22.9182091,
                                    22.9314365,
                                    22.9658337,
                                    22.984354,
                                    23.0663738,
                                    23.0875416,
                                    23.0954781,
                                    23.0954781,
                                    23.0981255,
                                    23.1192894,
                                    23.14575,
                                    23.1642704,
                                    23.1642704
                                ],
                                "y": [
                                    15.1103535,
                                    15.1050625,
                                    15.0971251,
                                    15.0891867,
                                    15.0786037,
                                    15.0706663,
                                    15.0600824,
                                    15.052145,
                                    15.0680208,
                                    15.0838947,
                                    15.1315203,
                                    15.1606245,
                                    15.213541,
                                    15.2399988,
                                    15.2585201,
                                    15.274395,
                                    15.2876244,
                                    15.2929163,
                                    15.2929163,
                                    15.2876244,
                                    15.274395,
                                    15.2347088,
                                    15.1870832,
                                    15.1606245,
                                    15.1315203,
                                    15.0997705,
                                    15.0891867,
                                    15.0786037,
                                    15.0891867,
                                    15.1050625,
                                    15.1209373,
                                    15.1394587,
                                    15.157979,
                                    15.1764994,
                                    15.2161865,
                                    15.2267704,
                                    15.2479372,
                                    15.2532291,
                                    15.2479372,
                                    15.2452908,
                                    15.2399988,
                                    15.2320623,
                                    15.2003126,
                                    15.1235828,
                                    15.0944786,
                                    15.0468531,
                                    15.0309792,
                                    15.0124588,
                                    14.996583,
                                    14.9807072,
                                    14.9621868,
                                    14.9304371,
                                    14.9145622,
                                    14.9013329,
                                    14.8775206,
                                    14.8748751,
                                    14.8722286,
                                    14.8616447,
                                    14.8589993,
                                    14.8589993,
                                    14.8642912,
                                    14.8828125,
                                    14.9119167,
                                    14.9251451,
                                    14.9542494,
                                    14.9648333,
                                    14.9833536,
                                    15.052145,
                                    15.0786037,
                                    15.0997705,
                                    15.1235828,
                                    15.2003126,
                                    15.2214785,
                                    15.2399988,
                                    15.2664585,
                                    15.2902708,
                                    15.3061447,
                                    15.3378954,
                                    15.380229,
                                    15.3961029,
                                    15.435791,
                                    15.441083,
                                    15.441083,
                                    15.435791,
                                    15.4304991,
                                    15.4093332,
                                    15.3749371,
                                    15.3564167,
                                    15.3564167
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    45,
                                    60,
                                    83,
                                    90,
                                    105,
                                    113,
                                    128,
                                    135,
                                    143,
                                    150,
                                    158,
                                    165,
                                    173,
                                    180,
                                    188,
                                    203,
                                    218,
                                    226,
                                    233,
                                    248,
                                    256,
                                    271,
                                    301,
                                    308,
                                    316,
                                    323,
                                    331,
                                    338,
                                    353,
                                    361,
                                    383,
                                    413,
                                    428,
                                    466,
                                    488,
                                    496,
                                    511,
                                    533,
                                    541,
                                    556,
                                    563,
                                    571,
                                    579,
                                    586,
                                    594,
                                    609,
                                    616,
                                    624,
                                    646,
                                    654,
                                    661,
                                    669,
                                    684,
                                    691,
                                    706,
                                    729,
                                    744,
                                    751,
                                    766,
                                    774,
                                    781,
                                    804,
                                    811,
                                    819,
                                    826,
                                    849,
                                    856,
                                    871,
                                    886,
                                    901,
                                    909,
                                    931,
                                    954,
                                    961,
                                    992,
                                    1007,
                                    1037,
                                    1052,
                                    1059,
                                    1074,
                                    1089,
                                    1097,
                                    1131
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ebc"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ebb",
                        "label": "@"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ebd",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "mm",
                            "man",
                            "emm",
                            "aum",
                            "amn"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    23.7172489,
                                    23.7119579,
                                    23.706665,
                                    23.6987286,
                                    23.6934376,
                                    23.6907921,
                                    23.6960812,
                                    23.7013741,
                                    23.7357693,
                                    23.7913322,
                                    23.8521881,
                                    23.8707085,
                                    23.8892288,
                                    23.8892288,
                                    23.8786449,
                                    23.8680611,
                                    23.8548336,
                                    23.8416042,
                                    23.828373,
                                    23.8098526,
                                    23.7807484,
                                    23.7225418,
                                    23.6987286,
                                    23.6828537,
                                    23.6696243,
                                    23.6749172,
                                    23.6854992,
                                    23.7093124,
                                    23.7225418,
                                    23.7463531,
                                    23.7648735,
                                    23.8442497,
                                    23.8971653,
                                    23.9368534,
                                    23.9500809,
                                    23.9712486,
                                    23.984478,
                                    23.984478,
                                    23.9871254,
                                    23.9871254,
                                    23.9924164,
                                    23.9977074,
                                    24.0082912,
                                    24.0241661,
                                    24.0506248,
                                    24.1220608,
                                    24.1299992,
                                    24.1352921,
                                    24.1379375,
                                    24.1326447,
                                    24.1247082,
                                    24.0691452,
                                    24.0241661,
                                    24.0162277,
                                    24.0135841,
                                    24.0135841,
                                    24.0188732,
                                    24.0241661,
                                    24.0321045,
                                    24.0373955,
                                    24.0426884,
                                    24.0506248,
                                    24.0612087,
                                    24.0744362,
                                    24.1220608,
                                    24.1485195,
                                    24.1908531,
                                    24.206728,
                                    24.2384777,
                                    24.2596455,
                                    24.2675838,
                                    24.2728729,
                                    24.2808113,
                                    24.2861042,
                                    24.2966881,
                                    24.3019772,
                                    24.3072701,
                                    24.3099155,
                                    24.312561,
                                    24.3099155,
                                    24.3099155,
                                    24.3046246,
                                    24.2966881,
                                    24.2966881,
                                    24.3019772,
                                    24.3178539,
                                    24.3257923,
                                    24.3390198,
                                    24.3443127,
                                    24.3522491,
                                    24.3760605,
                                    24.4157486,
                                    24.4554367,
                                    24.4739571,
                                    24.4713116,
                                    24.4739571,
                                    24.4739571,
                                    24.4713116,
                                    24.4607277,
                                    24.4369164,
                                    24.434269,
                                    24.4448528,
                                    24.4660206,
                                    24.4818954,
                                    24.4977684,
                                    24.52952,
                                    24.5612698,
                                    24.579792,
                                    24.6062489,
                                    24.6115398,
                                    24.6221237,
                                    24.6300602,
                                    24.6406441,
                                    24.6829796,
                                    24.696207,
                                    24.7014999,
                                    24.6988525,
                                    24.6988525
                                ],
                                "y": [
                                    15.0891867,
                                    15.0944786,
                                    15.0971251,
                                    15.0971251,
                                    15.0997705,
                                    15.102417,
                                    15.1156454,
                                    15.1182909,
                                    15.1235828,
                                    15.112999,
                                    15.0891867,
                                    15.0733128,
                                    15.0442085,
                                    15.0230408,
                                    14.9754162,
                                    14.9516029,
                                    14.935729,
                                    14.9251451,
                                    14.9198532,
                                    14.9224997,
                                    14.9330826,
                                    14.9754162,
                                    15.0018749,
                                    15.0283327,
                                    15.0653744,
                                    15.0971251,
                                    15.1209373,
                                    15.1500406,
                                    15.1712084,
                                    15.1764994,
                                    15.1844368,
                                    15.1764994,
                                    15.157979,
                                    15.1315203,
                                    15.1209373,
                                    15.0759583,
                                    15.0256872,
                                    14.9939365,
                                    14.9807072,
                                    14.9701252,
                                    14.9595413,
                                    14.9542494,
                                    14.9463129,
                                    14.9383745,
                                    14.9304371,
                                    14.9277906,
                                    14.9330826,
                                    14.941021,
                                    14.9489574,
                                    14.9648333,
                                    14.9807072,
                                    15.0230408,
                                    15.0653744,
                                    15.0786037,
                                    15.0944786,
                                    15.1050625,
                                    15.1262283,
                                    15.1341667,
                                    15.1394587,
                                    15.1447496,
                                    15.1447496,
                                    15.1473951,
                                    15.1447496,
                                    15.1368122,
                                    15.1288748,
                                    15.1182909,
                                    15.107708,
                                    15.1050625,
                                    15.0891867,
                                    15.0812492,
                                    15.0759583,
                                    15.0600824,
                                    15.0468531,
                                    15.0336246,
                                    14.9939365,
                                    14.9780626,
                                    14.9674788,
                                    14.9595413,
                                    14.9516029,
                                    14.9568949,
                                    14.9621868,
                                    14.9780626,
                                    15.0230408,
                                    15.0706663,
                                    15.0944786,
                                    15.1262283,
                                    15.1341667,
                                    15.1421032,
                                    15.1421032,
                                    15.1394587,
                                    15.1262283,
                                    15.0706663,
                                    14.9833536,
                                    14.9489574,
                                    14.9436665,
                                    14.9436665,
                                    14.9489574,
                                    14.9516029,
                                    14.9780626,
                                    15.052145,
                                    15.0971251,
                                    15.1156454,
                                    15.1394587,
                                    15.1447496,
                                    15.1447496,
                                    15.1341667,
                                    15.107708,
                                    15.0759583,
                                    14.9992285,
                                    14.9886456,
                                    14.9780626,
                                    14.9727707,
                                    14.9727707,
                                    14.9859991,
                                    14.991291,
                                    14.996583,
                                    14.996583,
                                    14.996583
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    83,
                                    90,
                                    106,
                                    121,
                                    136,
                                    143,
                                    158,
                                    166,
                                    181,
                                    188,
                                    196,
                                    203,
                                    211,
                                    218,
                                    226,
                                    241,
                                    248,
                                    256,
                                    271,
                                    278,
                                    286,
                                    301,
                                    308,
                                    316,
                                    323,
                                    346,
                                    361,
                                    376,
                                    383,
                                    398,
                                    413,
                                    428,
                                    436,
                                    443,
                                    458,
                                    466,
                                    473,
                                    481,
                                    489,
                                    511,
                                    519,
                                    526,
                                    534,
                                    541,
                                    549,
                                    564,
                                    579,
                                    586,
                                    594,
                                    601,
                                    616,
                                    624,
                                    631,
                                    639,
                                    646,
                                    654,
                                    661,
                                    669,
                                    684,
                                    691,
                                    706,
                                    714,
                                    729,
                                    736,
                                    744,
                                    759,
                                    766,
                                    774,
                                    782,
                                    789,
                                    797,
                                    804,
                                    812,
                                    857,
                                    864,
                                    872,
                                    887,
                                    902,
                                    910,
                                    925,
                                    932,
                                    947,
                                    955,
                                    962,
                                    977,
                                    1000,
                                    1022,
                                    1037,
                                    1045,
                                    1052,
                                    1059,
                                    1067,
                                    1082,
                                    1104,
                                    1119,
                                    1127,
                                    1142,
                                    1149,
                                    1157,
                                    1172,
                                    1187,
                                    1202,
                                    1232,
                                    1239,
                                    1247,
                                    1255,
                                    1270,
                                    1292,
                                    1300,
                                    1307,
                                    1315,
                                    1375
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ebf"
                            },
                            {
                                "x": [
                                    24.7597084,
                                    24.7729359,
                                    24.7808762,
                                    24.7888126,
                                    24.7914562,
                                    24.7702923,
                                    24.7253113,
                                    24.696207,
                                    24.6697483,
                                    24.6591644,
                                    24.6538734,
                                    24.6591644,
                                    24.6776867,
                                    24.6829796,
                                    24.6909161,
                                    24.6988525,
                                    24.7067909,
                                    24.7200203,
                                    24.7358952,
                                    24.7544155,
                                    24.8152695,
                                    24.8258533,
                                    24.8284988,
                                    24.812624,
                                    24.807333,
                                    24.807333,
                                    24.7941036,
                                    24.7861652,
                                    24.7861652,
                                    24.7914562,
                                    24.7993965,
                                    24.8179169,
                                    24.8258533,
                                    24.8523121,
                                    24.862896,
                                    24.8893528,
                                    24.9211044,
                                    24.97402,
                                    24.9978333,
                                    25.0084152,
                                    25.0163536,
                                    25.029583,
                                    25.0348759,
                                    25.0375195,
                                    25.0348759,
                                    25.0110607,
                                    24.9978333,
                                    24.9925404,
                                    25.0031242,
                                    25.0137081,
                                    25.0930824,
                                    25.1433525,
                                    25.1698112,
                                    25.1777496,
                                    25.185688,
                                    25.206852,
                                    25.2174358,
                                    25.2227287,
                                    25.2227287,
                                    25.2147923,
                                    25.2147923,
                                    25.2094994,
                                    25.2094994,
                                    25.2094994
                                ],
                                "y": [
                                    14.991291,
                                    14.9886456,
                                    14.9833536,
                                    14.9807072,
                                    14.9780626,
                                    14.9754162,
                                    14.9992285,
                                    15.0203953,
                                    15.0468531,
                                    15.0706663,
                                    15.1156454,
                                    15.1315203,
                                    15.157979,
                                    15.163271,
                                    15.1659164,
                                    15.1659164,
                                    15.163271,
                                    15.1553326,
                                    15.1394587,
                                    15.1262283,
                                    15.0468531,
                                    15.0309792,
                                    15.0230408,
                                    15.0389166,
                                    15.052145,
                                    15.0812492,
                                    15.0944786,
                                    15.112999,
                                    15.1262283,
                                    15.1368122,
                                    15.1473951,
                                    15.157979,
                                    15.1606245,
                                    15.1526871,
                                    15.1447496,
                                    15.1156454,
                                    15.0759583,
                                    14.9833536,
                                    14.935729,
                                    14.9277906,
                                    14.9251451,
                                    14.9383745,
                                    14.9516029,
                                    14.9780626,
                                    15.0071669,
                                    15.0997705,
                                    15.1341667,
                                    15.1421032,
                                    15.1368122,
                                    15.1288748,
                                    15.0362711,
                                    14.9886456,
                                    14.9727707,
                                    14.9727707,
                                    14.9754162,
                                    14.991291,
                                    15.0124588,
                                    15.0362711,
                                    15.0838947,
                                    15.1103535,
                                    15.1288748,
                                    15.1526871,
                                    15.157979,
                                    15.157979
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    67,
                                    82,
                                    90,
                                    97,
                                    105,
                                    120,
                                    127,
                                    142,
                                    150,
                                    157,
                                    165,
                                    172,
                                    180,
                                    187,
                                    195,
                                    225,
                                    233,
                                    240,
                                    285,
                                    293,
                                    300,
                                    308,
                                    315,
                                    323,
                                    330,
                                    338,
                                    345,
                                    353,
                                    368,
                                    375,
                                    390,
                                    405,
                                    428,
                                    443,
                                    450,
                                    458,
                                    480,
                                    488,
                                    495,
                                    503,
                                    525,
                                    540,
                                    548,
                                    585,
                                    593,
                                    623,
                                    638,
                                    653,
                                    661,
                                    668,
                                    683,
                                    691,
                                    698,
                                    713,
                                    721,
                                    728,
                                    743,
                                    751,
                                    782
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ec0"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ebe",
                        "label": "mm"
                    }
                ],
                "label": "@ mm"
            },
            "__v": 0
        },
        {
            "_id": "6071cfd3357fff0f20985ec1",
            "id": 20,
            "type": "text",
            "boundingBox": {
                "x": 16.2296677,
                "y": 17.5737495,
                "width": 7.38426971,
                "height": 4.45533371
            },
            "text": {
                "_id": "6071cfd3357fff0f20985ec2",
                "words": [
                    {
                        "candidates": [
                            "va",
                            "van",
                            "vu",
                            "von",
                            "vou"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    17.2296677,
                                    17.2296677,
                                    17.2323112,
                                    17.2349567,
                                    17.2402496,
                                    17.2852287,
                                    17.3116875,
                                    17.3090401,
                                    17.3116875,
                                    17.3196239,
                                    17.3222713,
                                    17.3381443,
                                    17.3751869,
                                    17.4572086,
                                    17.4730835,
                                    17.48102,
                                    17.4889584,
                                    17.5974369,
                                    17.6900406,
                                    17.7270832,
                                    17.7323742,
                                    17.7323742,
                                    17.7350197,
                                    17.7456036,
                                    17.7456036
                                ],
                                "y": [
                                    19.2193317,
                                    19.2246246,
                                    19.2272701,
                                    19.232563,
                                    19.237854,
                                    19.1134987,
                                    18.9388752,
                                    18.7007504,
                                    18.6002083,
                                    18.576395,
                                    18.5869789,
                                    18.8780193,
                                    19.015604,
                                    19.1717072,
                                    19.1796455,
                                    19.1796455,
                                    19.1664162,
                                    18.9838543,
                                    18.7351456,
                                    18.6293125,
                                    18.6002083,
                                    18.5816879,
                                    18.576395,
                                    18.5737495,
                                    18.5737495
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    90,
                                    113,
                                    135,
                                    150,
                                    158,
                                    188,
                                    225,
                                    240,
                                    270,
                                    278,
                                    285,
                                    300,
                                    330,
                                    353,
                                    368,
                                    375,
                                    390,
                                    420,
                                    428,
                                    462
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ec4"
                            },
                            {
                                "x": [
                                    17.9863758,
                                    17.9784355,
                                    17.9678555,
                                    17.9546242,
                                    17.9387512,
                                    17.9122906,
                                    17.8673115,
                                    17.8302708,
                                    17.7641239,
                                    17.7376671,
                                    17.7191448,
                                    17.7244358,
                                    17.7350197,
                                    17.7482491,
                                    17.7641239,
                                    17.803812,
                                    17.854084,
                                    17.9546242,
                                    17.9969578,
                                    18.0181236,
                                    18.0445824,
                                    18.0525208,
                                    18.0551662,
                                    18.0472279,
                                    18.036644,
                                    18.0234165,
                                    18.0181236,
                                    18.0048962,
                                    17.9652081,
                                    17.9466877,
                                    17.9413948,
                                    17.9440403,
                                    17.9519787,
                                    17.9810829,
                                    17.9996033,
                                    18.0154781,
                                    18.0313549,
                                    18.0551662,
                                    18.1186676,
                                    18.1901035,
                                    18.2244987,
                                    18.2324371,
                                    18.2324371,
                                    18.2271442,
                                    18.2192078,
                                    18.2033329,
                                    18.2006874,
                                    18.2059784,
                                    18.2139149,
                                    18.2271442,
                                    18.2403755,
                                    18.2588959,
                                    18.3673744,
                                    18.3911877,
                                    18.4070625,
                                    18.4176464,
                                    18.4176464,
                                    18.4044151,
                                    18.3938313,
                                    18.3911877,
                                    18.3938313,
                                    18.4070625,
                                    18.4282284,
                                    18.4546871,
                                    18.4996662,
                                    18.5499363,
                                    18.5499363
                                ],
                                "y": [
                                    18.9812069,
                                    18.9732704,
                                    18.9626865,
                                    18.9521027,
                                    18.9415188,
                                    18.9309387,
                                    18.9282913,
                                    18.9415188,
                                    19.0182495,
                                    19.0605831,
                                    19.1267281,
                                    19.1452503,
                                    19.1637707,
                                    19.1796455,
                                    19.1849384,
                                    19.182291,
                                    19.1664162,
                                    19.1029148,
                                    19.0658741,
                                    19.0394154,
                                    18.9785633,
                                    18.9468117,
                                    18.8780193,
                                    18.8568535,
                                    18.8409786,
                                    18.8356876,
                                    18.8356876,
                                    18.8462715,
                                    18.9018326,
                                    18.9494591,
                                    18.9864998,
                                    19.0288315,
                                    19.0605831,
                                    19.1108551,
                                    19.1293755,
                                    19.1373119,
                                    19.1399574,
                                    19.1346664,
                                    19.0870419,
                                    19.015604,
                                    18.9573956,
                                    18.8938961,
                                    18.8833122,
                                    18.8938961,
                                    18.909771,
                                    18.9732704,
                                    19.0235424,
                                    19.0394154,
                                    19.0473537,
                                    19.0526466,
                                    19.0499992,
                                    19.0420628,
                                    18.9521027,
                                    18.9229984,
                                    18.8965416,
                                    18.8700829,
                                    18.8303947,
                                    18.8780193,
                                    18.9521027,
                                    18.9864998,
                                    19.0182495,
                                    19.0420628,
                                    19.0526466,
                                    19.0526466,
                                    19.0314789,
                                    18.9917908,
                                    18.9917908
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    53,
                                    68,
                                    90,
                                    98,
                                    113,
                                    120,
                                    128,
                                    135,
                                    143,
                                    150,
                                    158,
                                    173,
                                    180,
                                    188,
                                    203,
                                    210,
                                    225,
                                    233,
                                    240,
                                    248,
                                    255,
                                    270,
                                    286,
                                    293,
                                    301,
                                    308,
                                    316,
                                    331,
                                    338,
                                    346,
                                    353,
                                    361,
                                    376,
                                    391,
                                    406,
                                    421,
                                    436,
                                    458,
                                    466,
                                    481,
                                    496,
                                    503,
                                    511,
                                    518,
                                    526,
                                    533,
                                    556,
                                    563,
                                    571,
                                    578,
                                    593,
                                    631,
                                    639,
                                    646,
                                    654,
                                    662,
                                    669,
                                    677,
                                    684,
                                    692,
                                    747
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ec5"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ec3",
                        "label": "va"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ec6",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "www.ae",
                            "www.",
                            "www.u",
                            "www.a",
                            "www.oe"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    18.5896244,
                                    18.5816879,
                                    18.5631676,
                                    18.5658112,
                                    18.576395,
                                    18.6054993,
                                    18.626667,
                                    18.6504784,
                                    18.6689987,
                                    18.6875191,
                                    18.7060394,
                                    18.7245617,
                                    18.7536659,
                                    18.7563114,
                                    18.743082,
                                    18.7510204,
                                    18.7748318,
                                    18.7907066,
                                    18.8356876,
                                    18.8621464,
                                    18.8938961,
                                    18.9256458,
                                    18.9256458,
                                    18.9229984,
                                    18.909771,
                                    18.8938961,
                                    18.8833122,
                                    18.8727283,
                                    18.8515625,
                                    18.8356876,
                                    18.8118744,
                                    18.8224583,
                                    18.8356876,
                                    18.8515625,
                                    18.8806667,
                                    18.9177074,
                                    18.9732704,
                                    19.0367699,
                                    19.0367699
                                ],
                                "y": [
                                    18.803936,
                                    18.8171673,
                                    18.9256458,
                                    18.970623,
                                    19.0235424,
                                    19.0605831,
                                    19.076458,
                                    19.0870419,
                                    19.0923328,
                                    19.0870419,
                                    19.076458,
                                    19.0605831,
                                    19.015604,
                                    18.9494591,
                                    18.9256458,
                                    18.9335823,
                                    18.9494591,
                                    18.9547501,
                                    18.9573956,
                                    18.9494591,
                                    18.9229984,
                                    18.8833122,
                                    18.8568535,
                                    18.8462715,
                                    18.8356876,
                                    18.8303947,
                                    18.8330421,
                                    18.8409786,
                                    18.8674355,
                                    18.8965416,
                                    18.9732704,
                                    19.020895,
                                    19.0420628,
                                    19.0605831,
                                    19.071167,
                                    19.0738106,
                                    19.0579357,
                                    19.0314789,
                                    19.0314789
                                ],
                                "t": [
                                    0,
                                    8,
                                    53,
                                    68,
                                    83,
                                    91,
                                    98,
                                    106,
                                    113,
                                    121,
                                    128,
                                    136,
                                    151,
                                    173,
                                    188,
                                    233,
                                    248,
                                    256,
                                    271,
                                    279,
                                    294,
                                    309,
                                    324,
                                    331,
                                    339,
                                    346,
                                    354,
                                    361,
                                    369,
                                    376,
                                    391,
                                    406,
                                    414,
                                    421,
                                    429,
                                    436,
                                    444,
                                    451,
                                    510
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ec8"
                            },
                            {
                                "x": [
                                    19.2431469,
                                    19.237854,
                                    19.232563,
                                    19.2272701,
                                    19.2193317,
                                    19.2113953,
                                    19.2034588,
                                    19.1981659,
                                    19.1849384,
                                    19.177,
                                    19.1531868,
                                    19.1399574,
                                    19.1187916,
                                    19.1161461,
                                    19.1187916,
                                    19.1267281,
                                    19.1399574,
                                    19.1531868,
                                    19.1664162,
                                    19.1849384,
                                    19.2246246,
                                    19.2696037,
                                    19.2907715,
                                    19.2960625,
                                    19.2960625,
                                    19.2907715,
                                    19.2775421,
                                    19.2696037,
                                    19.2696037,
                                    19.2775421,
                                    19.2881241,
                                    19.3013535,
                                    19.3145828,
                                    19.3304577,
                                    19.3754387,
                                    19.4257069,
                                    19.4495201,
                                    19.4548111,
                                    19.4548111,
                                    19.4521656,
                                    19.4521656,
                                    19.4336452,
                                    19.4283543,
                                    19.4415836,
                                    19.4627495,
                                    19.4839153,
                                    19.4944992,
                                    19.5606461,
                                    19.576519,
                                    19.5844574,
                                    19.5871029,
                                    19.5923958,
                                    19.5923958,
                                    19.5923958,
                                    19.5950413,
                                    19.6056232,
                                    19.6056232
                                ],
                                "y": [
                                    18.8912506,
                                    18.8912506,
                                    18.8886032,
                                    18.8859577,
                                    18.8833122,
                                    18.8780193,
                                    18.8753738,
                                    18.8753738,
                                    18.8806667,
                                    18.8886032,
                                    18.9229984,
                                    18.9521027,
                                    19.0235424,
                                    19.0552902,
                                    19.0791035,
                                    19.1029148,
                                    19.1161461,
                                    19.1240826,
                                    19.1214352,
                                    19.1134987,
                                    19.0738106,
                                    19.015604,
                                    18.960043,
                                    18.9335823,
                                    18.9203548,
                                    18.9309387,
                                    19.0103111,
                                    19.0420628,
                                    19.0685196,
                                    19.0843945,
                                    19.0976238,
                                    19.1029148,
                                    19.1029148,
                                    19.0976238,
                                    19.0605831,
                                    19.0023746,
                                    18.9468117,
                                    18.915062,
                                    18.8965416,
                                    18.8886032,
                                    18.8938961,
                                    19.0182495,
                                    19.1029148,
                                    19.132019,
                                    19.1531868,
                                    19.1611233,
                                    19.1584797,
                                    19.0791035,
                                    19.0447083,
                                    19.0182495,
                                    18.9891453,
                                    18.9679794,
                                    18.9626865,
                                    18.9785633,
                                    18.9838543,
                                    18.9917908,
                                    18.9917908
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    31,
                                    38,
                                    46,
                                    53,
                                    68,
                                    76,
                                    91,
                                    98,
                                    113,
                                    121,
                                    128,
                                    136,
                                    143,
                                    151,
                                    158,
                                    166,
                                    181,
                                    196,
                                    211,
                                    218,
                                    233,
                                    256,
                                    278,
                                    286,
                                    293,
                                    301,
                                    308,
                                    316,
                                    323,
                                    331,
                                    346,
                                    361,
                                    376,
                                    383,
                                    391,
                                    398,
                                    413,
                                    444,
                                    459,
                                    466,
                                    474,
                                    481,
                                    489,
                                    511,
                                    519,
                                    526,
                                    534,
                                    541,
                                    549,
                                    586,
                                    594,
                                    601,
                                    632
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ec9"
                            },
                            {
                                "x": [
                                    20.0316029,
                                    20.0316029,
                                    20.0316029,
                                    20.0342503,
                                    20.0448341,
                                    20.0739384,
                                    20.1003952,
                                    20.1242065,
                                    20.1850624,
                                    20.2062302,
                                    20.2035828,
                                    20.1929989,
                                    20.1797695,
                                    20.1665421,
                                    20.1559582,
                                    20.1294994,
                                    20.071291,
                                    20.0183754,
                                    20.0130825,
                                    20.021019,
                                    20.0368958,
                                    20.0633545,
                                    20.1003952,
                                    20.1347904,
                                    20.1612492,
                                    20.182415,
                                    20.2353344,
                                    20.2512074,
                                    20.2591457,
                                    20.2591457,
                                    20.2617912,
                                    20.2617912,
                                    20.2591457,
                                    20.293541,
                                    20.3199997,
                                    20.3358746,
                                    20.3358746
                                ],
                                "y": [
                                    19.1187916,
                                    19.1267281,
                                    19.132019,
                                    19.1373119,
                                    19.1346664,
                                    19.1161461,
                                    19.0923328,
                                    19.0685196,
                                    18.9759159,
                                    18.8938961,
                                    18.8436241,
                                    18.7907066,
                                    18.7563114,
                                    18.7325001,
                                    18.7245617,
                                    18.7404366,
                                    18.8356876,
                                    18.9944363,
                                    19.0658741,
                                    19.1214352,
                                    19.1743546,
                                    19.2113953,
                                    19.2352066,
                                    19.2457905,
                                    19.2431469,
                                    19.2272701,
                                    19.1664162,
                                    19.1373119,
                                    19.1108551,
                                    19.0817509,
                                    19.0658741,
                                    19.0526466,
                                    19.0447083,
                                    19.0314789,
                                    19.020895,
                                    19.020895,
                                    19.020895
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    44,
                                    52,
                                    59,
                                    82,
                                    97,
                                    104,
                                    112,
                                    119,
                                    127,
                                    135,
                                    157,
                                    172,
                                    187,
                                    195,
                                    202,
                                    210,
                                    217,
                                    225,
                                    232,
                                    240,
                                    247,
                                    262,
                                    270,
                                    277,
                                    285,
                                    292,
                                    300,
                                    307,
                                    360,
                                    367,
                                    375,
                                    418
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eca"
                            },
                            {
                                "x": [
                                    20.3782063,
                                    20.3676262,
                                    20.3649788,
                                    20.3623333,
                                    20.3623333,
                                    20.3702698,
                                    20.3782063,
                                    20.3834991,
                                    20.394083,
                                    20.4443531,
                                    20.510498,
                                    20.5369587,
                                    20.5581245,
                                    20.5819378,
                                    20.5819378,
                                    20.5713539,
                                    20.5501862,
                                    20.5184383,
                                    20.510498,
                                    20.4840412,
                                    20.4284782,
                                    20.3914375,
                                    20.3967285,
                                    20.4126034,
                                    20.4390621,
                                    20.4787502,
                                    20.5369587,
                                    20.6004581,
                                    20.6586666,
                                    20.7115822,
                                    20.7512703,
                                    20.777729,
                                    20.788311,
                                    20.788311,
                                    20.7830219,
                                    20.772438,
                                    20.7618542,
                                    20.7353954,
                                    20.7248116,
                                    20.7142296,
                                    20.7036457,
                                    20.6983547,
                                    20.6745415,
                                    20.6586666,
                                    20.6348534,
                                    20.6322079,
                                    20.6374989,
                                    20.6480827,
                                    20.6639576,
                                    20.677187,
                                    20.6930618,
                                    20.7803745,
                                    20.788311,
                                    20.8147697,
                                    20.8253536,
                                    20.8306465,
                                    20.8412285,
                                    20.8518105,
                                    20.8518105
                                ],
                                "y": [
                                    19.0526466,
                                    19.0605831,
                                    19.0658741,
                                    19.071167,
                                    19.0791035,
                                    19.0923328,
                                    19.0949783,
                                    19.0976238,
                                    19.0949783,
                                    19.0685196,
                                    19.0129585,
                                    18.9838543,
                                    18.9521027,
                                    18.8806667,
                                    18.7827702,
                                    18.7457275,
                                    18.7033958,
                                    18.6822281,
                                    18.6822281,
                                    18.7139797,
                                    18.8171673,
                                    18.9468117,
                                    19.0473537,
                                    19.0843945,
                                    19.1108551,
                                    19.1214352,
                                    19.1134987,
                                    19.0896873,
                                    19.0579357,
                                    19.0288315,
                                    19.0023746,
                                    18.9891453,
                                    18.9785633,
                                    18.970623,
                                    18.9626865,
                                    18.9573956,
                                    18.9468117,
                                    18.9282913,
                                    18.9203548,
                                    18.9177074,
                                    18.9229984,
                                    18.9282913,
                                    18.960043,
                                    18.9891453,
                                    19.0552902,
                                    19.0738106,
                                    19.0896873,
                                    19.0976238,
                                    19.1055622,
                                    19.1055622,
                                    19.1029148,
                                    19.0738106,
                                    19.0552902,
                                    19.0367699,
                                    19.0261879,
                                    19.0182495,
                                    19.0103111,
                                    19.0023746,
                                    19.0023746
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    45,
                                    53,
                                    61,
                                    68,
                                    83,
                                    98,
                                    106,
                                    113,
                                    128,
                                    143,
                                    151,
                                    158,
                                    173,
                                    181,
                                    196,
                                    211,
                                    226,
                                    241,
                                    248,
                                    256,
                                    263,
                                    271,
                                    278,
                                    286,
                                    293,
                                    301,
                                    308,
                                    316,
                                    323,
                                    331,
                                    338,
                                    346,
                                    361,
                                    368,
                                    376,
                                    383,
                                    391,
                                    406,
                                    413,
                                    428,
                                    436,
                                    443,
                                    451,
                                    459,
                                    466,
                                    474,
                                    489,
                                    496,
                                    504,
                                    511,
                                    519,
                                    526,
                                    534,
                                    569
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ecb"
                            },
                            {
                                "x": [
                                    20.9893951,
                                    20.9893951,
                                    20.9867496,
                                    20.9814587,
                                    20.9735203,
                                    20.9655819,
                                    20.954998,
                                    20.944418,
                                    20.9338341,
                                    20.8888531,
                                    20.8729782,
                                    20.8650417,
                                    20.8676872,
                                    20.8809147,
                                    20.899437,
                                    20.9206028,
                                    20.944418,
                                    20.9735203,
                                    20.9973335,
                                    21.0264378,
                                    21.0528946,
                                    21.0528946
                                ],
                                "y": [
                                    18.9203548,
                                    18.909771,
                                    18.8991871,
                                    18.8912506,
                                    18.8886032,
                                    18.8886032,
                                    18.8912506,
                                    18.8965416,
                                    18.9071236,
                                    18.970623,
                                    19.0103111,
                                    19.0499992,
                                    19.076458,
                                    19.1055622,
                                    19.1293755,
                                    19.1426029,
                                    19.1478958,
                                    19.1399574,
                                    19.1267281,
                                    19.1029148,
                                    19.076458,
                                    19.076458
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    45,
                                    53,
                                    60,
                                    75,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    143,
                                    150,
                                    158,
                                    199
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ecc"
                            },
                            {
                                "x": [
                                    21.6138134,
                                    21.6032295,
                                    21.5899982,
                                    21.5741253,
                                    21.560894,
                                    21.5212059,
                                    21.4524174,
                                    21.4074383,
                                    21.3968544,
                                    21.394207,
                                    21.399498,
                                    21.4074383,
                                    21.4206657,
                                    21.4312496,
                                    21.4868126,
                                    21.5476665,
                                    21.5794163,
                                    21.6243954,
                                    21.6455612,
                                    21.6852493,
                                    21.6984787,
                                    21.6984787,
                                    21.6931858,
                                    21.6773129,
                                    21.6243954,
                                    21.6217499,
                                    21.6323338,
                                    21.6482067,
                                    21.661438,
                                    21.6773129,
                                    21.6958332,
                                    21.7672691,
                                    21.822834,
                                    21.8307705,
                                    21.828125,
                                    21.8175411,
                                    21.8043137,
                                    21.7937298,
                                    21.7804985,
                                    21.7461033,
                                    21.7302284,
                                    21.7328739,
                                    21.7434578,
                                    21.7646255,
                                    21.7937298,
                                    21.8413544,
                                    21.8413544
                                ],
                                "y": [
                                    18.9203548,
                                    18.909771,
                                    18.9018326,
                                    18.8938961,
                                    18.8912506,
                                    18.8991871,
                                    18.9573956,
                                    19.0103111,
                                    19.0341244,
                                    19.0552902,
                                    19.0685196,
                                    19.076458,
                                    19.0817509,
                                    19.0817509,
                                    19.0632286,
                                    19.0182495,
                                    18.9891453,
                                    18.9282913,
                                    18.8859577,
                                    18.7325001,
                                    18.6478329,
                                    18.6346035,
                                    18.64254,
                                    18.7033958,
                                    18.9521027,
                                    18.9944363,
                                    19.0314789,
                                    19.0526466,
                                    19.0658741,
                                    19.0658741,
                                    19.0579357,
                                    19.0076656,
                                    18.9335823,
                                    18.9071236,
                                    18.8753738,
                                    18.854208,
                                    18.8462715,
                                    18.8489151,
                                    18.8621464,
                                    18.9335823,
                                    19.015604,
                                    19.0447083,
                                    19.0658741,
                                    19.0843945,
                                    19.0896873,
                                    19.076458,
                                    19.076458
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    45,
                                    68,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    143,
                                    158,
                                    165,
                                    181,
                                    188,
                                    211,
                                    226,
                                    241,
                                    256,
                                    271,
                                    301,
                                    308,
                                    316,
                                    323,
                                    331,
                                    338,
                                    346,
                                    361,
                                    376,
                                    383,
                                    391,
                                    398,
                                    406,
                                    413,
                                    421,
                                    436,
                                    451,
                                    458,
                                    466,
                                    473,
                                    481,
                                    488,
                                    518
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ecd"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ec7",
                        "label": "www.ae"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ece",
                        "label": "\n"
                    },
                    {
                        "candidates": [
                            "rue",
                            "rare",
                            "texte",
                            "reve",
                            "resae"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    17.3857708,
                                    17.3884163,
                                    17.3963528,
                                    17.4016438,
                                    17.4069366,
                                    17.409584,
                                    17.4122276,
                                    17.3751869,
                                    17.3698959,
                                    17.364603,
                                    17.3619595,
                                    17.3566666,
                                    17.3540192,
                                    17.3540192,
                                    17.364603,
                                    17.3990002,
                                    17.420166,
                                    17.4466248,
                                    17.5656872,
                                    17.5974369,
                                    17.6159592,
                                    17.6159592
                                ],
                                "y": [
                                    19.576519,
                                    19.5474148,
                                    19.5236034,
                                    19.5024376,
                                    19.4865627,
                                    19.4786243,
                                    19.5209579,
                                    19.9337063,
                                    20.005146,
                                    20.0183754,
                                    20.021019,
                                    20.010437,
                                    19.9972076,
                                    19.9389992,
                                    19.8993111,
                                    19.8331661,
                                    19.8119984,
                                    19.793478,
                                    19.7484989,
                                    19.7405624,
                                    19.7405624,
                                    19.7405624
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    75,
                                    128,
                                    143,
                                    150,
                                    165,
                                    172,
                                    180,
                                    195,
                                    203,
                                    218,
                                    225,
                                    233,
                                    255,
                                    263,
                                    270,
                                    308
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed0"
                            },
                            {
                                "x": [
                                    17.4572086,
                                    17.4598522,
                                    17.4651451,
                                    17.4783745,
                                    17.4889584,
                                    17.5021877,
                                    17.5207081,
                                    17.5392284,
                                    17.5974369,
                                    17.6847477,
                                    17.7085629,
                                    17.7085629,
                                    17.7006245,
                                    17.692688,
                                    17.6821041,
                                    17.6635838,
                                    17.6318321,
                                    17.6053753,
                                    17.581562,
                                    17.5498123,
                                    17.5471668,
                                    17.5498123,
                                    17.5577488,
                                    17.5762711,
                                    17.6080208,
                                    17.6529999,
                                    17.7773552,
                                    17.8461437,
                                    17.8858318,
                                    17.9069996,
                                    17.9255199,
                                    17.9361038,
                                    17.9361038,
                                    17.9255199,
                                    17.9043541,
                                    17.8302708,
                                    17.7985191,
                                    17.7614784,
                                    17.7561874,
                                    17.7588329,
                                    17.7588329
                                ],
                                "y": [
                                    20.0342503,
                                    20.0421867,
                                    20.0448341,
                                    20.0474777,
                                    20.0501232,
                                    20.0501232,
                                    20.0474777,
                                    20.0395412,
                                    19.9972076,
                                    19.9019585,
                                    19.8516865,
                                    19.838459,
                                    19.8252296,
                                    19.8199387,
                                    19.8199387,
                                    19.8278751,
                                    19.8516865,
                                    19.8807907,
                                    19.9231243,
                                    20.0157299,
                                    20.0448341,
                                    20.0633545,
                                    20.076582,
                                    20.0818748,
                                    20.0818748,
                                    20.0607071,
                                    19.9733944,
                                    19.9389992,
                                    19.9231243,
                                    19.9257698,
                                    19.9389992,
                                    19.9601669,
                                    19.9866238,
                                    20.021019,
                                    20.0580616,
                                    20.1347904,
                                    20.1586037,
                                    20.182415,
                                    20.1771259,
                                    20.1453743,
                                    20.1453743
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    45,
                                    52,
                                    67,
                                    90,
                                    105,
                                    112,
                                    120,
                                    127,
                                    135,
                                    142,
                                    150,
                                    157,
                                    165,
                                    173,
                                    180,
                                    188,
                                    196,
                                    203,
                                    211,
                                    218,
                                    225,
                                    233,
                                    240,
                                    248,
                                    255,
                                    263,
                                    270,
                                    278,
                                    285,
                                    300,
                                    308,
                                    316,
                                    323,
                                    338,
                                    431
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed1"
                            },
                            {
                                "x": [
                                    18.092207,
                                    18.1054363,
                                    18.1054363,
                                    18.0948544,
                                    18.0816231,
                                    18.0683956,
                                    18.0472279,
                                    18.0234165,
                                    18.0022507,
                                    17.9440403,
                                    17.9387512,
                                    17.9440403,
                                    17.9572716,
                                    17.9757919,
                                    17.9943123,
                                    18.0234165,
                                    18.0631027,
                                    18.0974998,
                                    18.1213112,
                                    18.137188,
                                    18.137188
                                ],
                                "y": [
                                    19.8649158,
                                    19.849041,
                                    19.8411026,
                                    19.8358116,
                                    19.8331661,
                                    19.8331661,
                                    19.84375,
                                    19.8622704,
                                    19.8913746,
                                    20.021019,
                                    20.0580616,
                                    20.0845203,
                                    20.1109791,
                                    20.1242065,
                                    20.1242065,
                                    20.1109791,
                                    20.076582,
                                    20.0342503,
                                    19.9892712,
                                    19.9469376,
                                    19.9469376
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    45,
                                    53,
                                    60,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    143,
                                    150,
                                    158,
                                    176
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed2"
                            },
                            {
                                "x": [
                                    18.1768742,
                                    18.1927509,
                                    18.2006874,
                                    18.2059784,
                                    18.2086239,
                                    18.2139149,
                                    18.2244987,
                                    18.2324371,
                                    18.2218552,
                                    18.2165623,
                                    18.2218552,
                                    18.2297916,
                                    18.248312,
                                    18.2774162,
                                    18.309166,
                                    18.3700199,
                                    18.3858948,
                                    18.3964787,
                                    18.4017715,
                                    18.4017715,
                                    18.4017715
                                ],
                                "y": [
                                    19.5738754,
                                    19.515667,
                                    19.4812698,
                                    19.4627495,
                                    19.4521656,
                                    19.4574585,
                                    19.5236034,
                                    19.5844574,
                                    19.8093548,
                                    19.9495831,
                                    19.9760418,
                                    19.9416466,
                                    19.8728542,
                                    19.8252296,
                                    19.788187,
                                    19.7432079,
                                    19.737915,
                                    19.7405624,
                                    19.7458534,
                                    19.7590828,
                                    19.7590828
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    53,
                                    75,
                                    83,
                                    105,
                                    128,
                                    135,
                                    173,
                                    188,
                                    195,
                                    203,
                                    218,
                                    225,
                                    233,
                                    240,
                                    248,
                                    275
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed3"
                            },
                            {
                                "x": [
                                    18.3171043,
                                    18.3144588,
                                    18.3197498,
                                    18.3250408,
                                    18.3356247,
                                    18.3488541,
                                    18.3647289,
                                    18.3832512,
                                    18.4176464,
                                    18.5023117,
                                    18.5340633,
                                    18.5525837,
                                    18.5552273,
                                    18.5525837,
                                    18.5393543,
                                    18.515543,
                                    18.4890823,
                                    18.4202919,
                                    18.3885403,
                                    18.3647289,
                                    18.3567905,
                                    18.3673744,
                                    18.3806038,
                                    18.3991241,
                                    18.4388123,
                                    18.4890823,
                                    18.5499363,
                                    18.6107903,
                                    18.6107903
                                ],
                                "y": [
                                    19.8702068,
                                    19.8754997,
                                    19.8807907,
                                    19.8887272,
                                    19.8993111,
                                    19.9072495,
                                    19.9098949,
                                    19.9072495,
                                    19.8940201,
                                    19.8331661,
                                    19.8014145,
                                    19.7749577,
                                    19.7643738,
                                    19.7537918,
                                    19.7484989,
                                    19.7484989,
                                    19.7564373,
                                    19.8093548,
                                    19.8543339,
                                    19.9098949,
                                    19.9469376,
                                    19.9813328,
                                    20.0024986,
                                    20.021019,
                                    20.0316029,
                                    20.0289574,
                                    20.010437,
                                    19.9760418,
                                    19.9760418
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    23,
                                    30,
                                    38,
                                    45,
                                    53,
                                    60,
                                    75,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    143,
                                    150,
                                    158,
                                    165,
                                    173,
                                    180,
                                    188,
                                    195,
                                    203,
                                    210,
                                    218,
                                    251
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed4"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ecf",
                        "label": "rue"
                    },
                    {
                        "candidates": [
                            ",",
                            "."
                        ],
                        "strokes": [
                            {
                                "x": [
                                    18.9494591,
                                    18.9547501,
                                    18.965332,
                                    18.9732704,
                                    18.9785633,
                                    18.9785633,
                                    18.970623,
                                    18.9521027,
                                    18.8886032,
                                    18.8700829,
                                    18.8700829
                                ],
                                "y": [
                                    20.0316029,
                                    20.0236664,
                                    20.0157299,
                                    20.010437,
                                    20.0077915,
                                    20.0157299,
                                    20.0289574,
                                    20.0607071,
                                    20.1533108,
                                    20.1771259,
                                    20.1771259
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    60,
                                    75,
                                    90,
                                    113,
                                    120,
                                    155
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed6"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ed5",
                        "label": ","
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ed7",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "ici",
                            "iéais",
                            "ais",
                            "iés",
                            "ien"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    19.4865627,
                                    19.4944992,
                                    19.4997902,
                                    19.5050831,
                                    19.5130196,
                                    19.5288944,
                                    19.5421238,
                                    19.5527077,
                                    19.5474148,
                                    19.5103741,
                                    19.4918537,
                                    19.470686,
                                    19.4442272,
                                    19.39925,
                                    19.3886662,
                                    19.3754387,
                                    19.3595619,
                                    19.3251667,
                                    19.3383942,
                                    19.3357506,
                                    19.3463345,
                                    19.3622074,
                                    19.4362907,
                                    19.4944992,
                                    19.5474148,
                                    19.5474148
                                ],
                                "y": [
                                    19.7776031,
                                    19.7643738,
                                    19.7564373,
                                    19.7511463,
                                    19.7484989,
                                    19.7617302,
                                    19.7961235,
                                    19.8728542,
                                    19.9866238,
                                    20.1453743,
                                    20.1903534,
                                    20.2247505,
                                    20.2512074,
                                    20.2803116,
                                    20.2856026,
                                    20.2856026,
                                    20.2644367,
                                    20.2432709,
                                    20.2273941,
                                    20.1850624,
                                    20.1638947,
                                    20.1453743,
                                    20.0898113,
                                    20.0633545,
                                    20.0368958,
                                    20.0368958
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    60,
                                    75,
                                    90,
                                    105,
                                    127,
                                    135,
                                    142,
                                    150,
                                    165,
                                    172,
                                    180,
                                    188,
                                    195,
                                    203,
                                    218,
                                    225,
                                    233,
                                    248,
                                    255,
                                    263,
                                    295
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ed9"
                            },
                            {
                                "x": [
                                    19.5844574,
                                    19.565937,
                                    19.565937,
                                    19.5685825,
                                    19.571228,
                                    19.571228
                                ],
                                "y": [
                                    19.6109161,
                                    19.6029778,
                                    19.6029778,
                                    19.6029778,
                                    19.6029778,
                                    19.6029778
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    30,
                                    37,
                                    72
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eda"
                            },
                            {
                                "x": [
                                    19.8940201,
                                    19.8887272,
                                    19.8754997,
                                    19.8543339,
                                    19.8146458,
                                    19.8119984,
                                    19.8119984
                                ],
                                "y": [
                                    19.5183105,
                                    19.5183105,
                                    19.5236034,
                                    19.6215,
                                    19.7352695,
                                    19.7484989,
                                    19.7484989
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    46,
                                    68,
                                    76,
                                    104
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985edb"
                            },
                            {
                                "x": [
                                    19.8993111,
                                    19.8966656,
                                    19.9019585,
                                    19.9178333,
                                    19.9337063,
                                    19.9972076,
                                    20.1056862,
                                    20.1242065,
                                    20.1268539,
                                    20.1242065,
                                    20.1083336,
                                    20.0845203,
                                    20.0474777,
                                    19.9945621,
                                    19.9442902,
                                    19.8622704,
                                    19.8411026,
                                    19.8358116,
                                    19.84375,
                                    19.8622704,
                                    19.8887272,
                                    19.9178333,
                                    19.9628105,
                                    19.9628105
                                ],
                                "y": [
                                    19.8463955,
                                    19.8516865,
                                    19.8543339,
                                    19.8569794,
                                    19.8543339,
                                    19.8225822,
                                    19.737915,
                                    19.7141037,
                                    19.6982269,
                                    19.6797066,
                                    19.6691227,
                                    19.6638336,
                                    19.6691227,
                                    19.6849995,
                                    19.7114582,
                                    19.788187,
                                    19.838459,
                                    19.8728542,
                                    19.9019585,
                                    19.9337063,
                                    19.9522305,
                                    19.9628105,
                                    19.9654579,
                                    19.9654579
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    30,
                                    38,
                                    53,
                                    75,
                                    83,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    150,
                                    158,
                                    165,
                                    173,
                                    181,
                                    188,
                                    196,
                                    203,
                                    295
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985edc"
                            },
                            {
                                "x": [
                                    20.3411655,
                                    20.3385201,
                                    20.3358746,
                                    20.3279381,
                                    20.3120613,
                                    20.2988338,
                                    20.2803116,
                                    20.2459145,
                                    20.2088737,
                                    20.1877079,
                                    20.1612492,
                                    20.1612492,
                                    20.1877079,
                                    20.2273941,
                                    20.2432709,
                                    20.2564983,
                                    20.3411655,
                                    20.3861465,
                                    20.4390621,
                                    20.4734573,
                                    20.5052071,
                                    20.5210819,
                                    20.5263748,
                                    20.5263748,
                                    20.5316658,
                                    20.5316658,
                                    20.5396023,
                                    20.5448952,
                                    20.5448952,
                                    20.5501862,
                                    20.555479,
                                    20.56077,
                                    20.5687065,
                                    20.5766449,
                                    20.616333,
                                    20.6216259,
                                    20.6348534,
                                    20.6454372,
                                    20.6507301,
                                    20.7115822,
                                    20.7301025,
                                    20.7301025,
                                    20.7353954,
                                    20.7248116,
                                    20.7009983,
                                    20.6930618,
                                    20.677187,
                                    20.6930618,
                                    20.7062912,
                                    20.7142296,
                                    20.7327499,
                                    20.7486248,
                                    20.788311,
                                    20.8491669,
                                    20.8703327,
                                    20.8809147,
                                    20.8888531,
                                    20.8967915,
                                    20.9047298,
                                    20.9126663,
                                    20.9206028,
                                    20.9258938,
                                    20.9179573,
                                    20.9100208,
                                    20.9020824,
                                    20.8623943,
                                    20.8200626,
                                    20.8200626
                                ],
                                "y": [
                                    19.7326241,
                                    19.7299786,
                                    19.7246876,
                                    19.7220421,
                                    19.7167492,
                                    19.7193947,
                                    19.7273331,
                                    19.7537918,
                                    19.8014145,
                                    19.8358116,
                                    19.904604,
                                    19.9337063,
                                    19.9839783,
                                    19.9998531,
                                    19.9972076,
                                    19.9919147,
                                    19.9019585,
                                    19.84375,
                                    19.7855415,
                                    19.7590828,
                                    19.7405624,
                                    19.7352695,
                                    19.7405624,
                                    19.7458534,
                                    19.7590828,
                                    19.7802505,
                                    19.8093548,
                                    19.8649158,
                                    19.8940201,
                                    19.9231243,
                                    19.9337063,
                                    19.9389992,
                                    19.9363537,
                                    19.9337063,
                                    19.8993111,
                                    19.8860836,
                                    19.8728542,
                                    19.8569794,
                                    19.84375,
                                    19.7484989,
                                    19.7220421,
                                    19.7220421,
                                    19.7326241,
                                    19.7405624,
                                    19.7802505,
                                    19.8093548,
                                    19.8966656,
                                    19.9337063,
                                    19.9389992,
                                    19.9442902,
                                    19.9337063,
                                    19.9204788,
                                    19.8702068,
                                    19.793478,
                                    19.7776031,
                                    19.7723122,
                                    19.7723122,
                                    19.7749577,
                                    19.7855415,
                                    19.8014145,
                                    19.8252296,
                                    19.8543339,
                                    19.9363537,
                                    19.9522305,
                                    19.9575195,
                                    19.9469376,
                                    19.9284153,
                                    19.9284153
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    45,
                                    52,
                                    67,
                                    82,
                                    90,
                                    98,
                                    105,
                                    120,
                                    135,
                                    143,
                                    150,
                                    173,
                                    188,
                                    196,
                                    203,
                                    211,
                                    219,
                                    227,
                                    235,
                                    242,
                                    250,
                                    257,
                                    264,
                                    272,
                                    287,
                                    294,
                                    302,
                                    317,
                                    324,
                                    347,
                                    354,
                                    362,
                                    369,
                                    377,
                                    414,
                                    422,
                                    429,
                                    436,
                                    444,
                                    452,
                                    459,
                                    482,
                                    497,
                                    504,
                                    512,
                                    527,
                                    534,
                                    550,
                                    572,
                                    580,
                                    587,
                                    595,
                                    602,
                                    610,
                                    617,
                                    625,
                                    632,
                                    655,
                                    662,
                                    670,
                                    685,
                                    692,
                                    816
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985edd"
                            },
                            {
                                "x": [
                                    20.6983547,
                                    20.7036457,
                                    20.7168751,
                                    20.7380409,
                                    20.7618542,
                                    20.7750816,
                                    20.7750816
                                ],
                                "y": [
                                    19.5871029,
                                    19.5871029,
                                    19.5897503,
                                    19.5976868,
                                    19.6003342,
                                    19.6029778,
                                    19.6029778
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    70
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ede"
                            },
                            {
                                "x": [
                                    20.1427288,
                                    20.1374378,
                                    20.1321449,
                                    20.1374378,
                                    20.1453743,
                                    20.2088737,
                                    20.2617912,
                                    20.2617912
                                ],
                                "y": [
                                    19.565937,
                                    19.5685825,
                                    19.5685825,
                                    19.565937,
                                    19.5632915,
                                    19.5341873,
                                    19.5050831,
                                    19.5050831
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    45,
                                    53,
                                    81
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985edf"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ed8",
                        "label": "ici"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ee0",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "avec",
                            "aux",
                            "arec",
                            "avc",
                            "avea"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    21.5767708,
                                    21.560894,
                                    21.5476665,
                                    21.5317898,
                                    21.4973946,
                                    21.4365406,
                                    21.4074383,
                                    21.383625,
                                    21.3412914,
                                    21.3359985,
                                    21.3412914,
                                    21.3651028,
                                    21.3915615,
                                    21.4233131,
                                    21.4603539,
                                    21.4947491,
                                    21.5264988,
                                    21.550312,
                                    21.5635414,
                                    21.5767708,
                                    21.5873528,
                                    21.5899982,
                                    21.5952911,
                                    21.5952911,
                                    21.5926456,
                                    21.5952911,
                                    21.6138134,
                                    21.6323338,
                                    21.6455612,
                                    21.6640816,
                                    21.6852493,
                                    21.7037697,
                                    21.7117062,
                                    21.7302284,
                                    21.7593327,
                                    21.7646255,
                                    21.7619781,
                                    21.7540417,
                                    21.7408123,
                                    21.7487488,
                                    21.7619781,
                                    21.7804985,
                                    21.8254776,
                                    21.8466454,
                                    21.8651657,
                                    21.8810406,
                                    21.9233742,
                                    21.9445419,
                                    21.9524784,
                                    22.0080414,
                                    22.0239162,
                                    22.0424366,
                                    22.0503731,
                                    22.0741863,
                                    22.0953541,
                                    22.1244583,
                                    22.1244583
                                ],
                                "y": [
                                    19.7670193,
                                    19.7537918,
                                    19.7352695,
                                    19.7246876,
                                    19.7193947,
                                    19.737915,
                                    19.7564373,
                                    19.7802505,
                                    19.859623,
                                    19.904604,
                                    19.9389992,
                                    19.9681034,
                                    19.9866238,
                                    19.9919147,
                                    19.9892712,
                                    19.9786873,
                                    19.9575195,
                                    19.9284153,
                                    19.9019585,
                                    19.8516865,
                                    19.8305187,
                                    19.8146458,
                                    19.8014145,
                                    19.788187,
                                    19.8649158,
                                    19.9019585,
                                    19.9495831,
                                    19.9654579,
                                    19.9681034,
                                    19.9628105,
                                    19.9469376,
                                    19.9257698,
                                    19.8993111,
                                    19.8675613,
                                    19.7723122,
                                    19.7617302,
                                    19.7696667,
                                    19.793478,
                                    19.8807907,
                                    19.9019585,
                                    19.9257698,
                                    19.9442902,
                                    19.9628105,
                                    19.9654579,
                                    19.9601669,
                                    19.9522305,
                                    19.9204788,
                                    19.8887272,
                                    19.8675613,
                                    19.7537918,
                                    19.7246876,
                                    19.7061672,
                                    19.7008743,
                                    19.6929379,
                                    19.6876469,
                                    19.6797066,
                                    19.6797066
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    37,
                                    52,
                                    60,
                                    67,
                                    82,
                                    90,
                                    97,
                                    105,
                                    112,
                                    120,
                                    127,
                                    135,
                                    142,
                                    150,
                                    158,
                                    173,
                                    180,
                                    188,
                                    195,
                                    210,
                                    263,
                                    270,
                                    277,
                                    285,
                                    292,
                                    300,
                                    307,
                                    315,
                                    322,
                                    330,
                                    360,
                                    367,
                                    397,
                                    412,
                                    435,
                                    442,
                                    450,
                                    457,
                                    464,
                                    472,
                                    479,
                                    487,
                                    502,
                                    517,
                                    524,
                                    555,
                                    562,
                                    577,
                                    585,
                                    607,
                                    615,
                                    622,
                                    681
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ee2"
                            },
                            {
                                "x": [
                                    22.1350422,
                                    22.1376858,
                                    22.1403332,
                                    22.1509171,
                                    22.161499,
                                    22.1720829,
                                    22.2144165,
                                    22.2752705,
                                    22.3202496,
                                    22.3334789,
                                    22.3387699,
                                    22.3440609,
                                    22.3387699,
                                    22.328186,
                                    22.3202496,
                                    22.2832088,
                                    22.222353,
                                    22.1958942,
                                    22.1773739,
                                    22.1720829,
                                    22.1773739,
                                    22.1879578,
                                    22.2064781,
                                    22.2567501,
                                    22.2620411,
                                    22.2911453,
                                    22.3123131,
                                    22.3255405,
                                    22.3308334,
                                    22.3361244,
                                    22.3387699,
                                    22.3387699,
                                    22.3387699
                                ],
                                "y": [
                                    19.8702068,
                                    19.8754997,
                                    19.8754997,
                                    19.8781452,
                                    19.8781452,
                                    19.8754997,
                                    19.8569794,
                                    19.8199387,
                                    19.7776031,
                                    19.7537918,
                                    19.7326241,
                                    19.7114582,
                                    19.6902905,
                                    19.6849995,
                                    19.6876469,
                                    19.7193947,
                                    19.7828941,
                                    19.8225822,
                                    19.8622704,
                                    19.8913746,
                                    19.9125423,
                                    19.9310627,
                                    19.9442902,
                                    19.9654579,
                                    19.9442902,
                                    19.9337063,
                                    19.9204788,
                                    19.9072495,
                                    19.8993111,
                                    19.8913746,
                                    19.8834381,
                                    19.8754997,
                                    19.8754997
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    53,
                                    68,
                                    83,
                                    90,
                                    98,
                                    105,
                                    120,
                                    135,
                                    143,
                                    158,
                                    173,
                                    180,
                                    188,
                                    195,
                                    203,
                                    210,
                                    218,
                                    233,
                                    240,
                                    248,
                                    255,
                                    263,
                                    270,
                                    278,
                                    285,
                                    293,
                                    325
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ee3"
                            },
                            {
                                "x": [
                                    22.5848331,
                                    22.5821857,
                                    22.5742493,
                                    22.5636654,
                                    22.5530815,
                                    22.5372086,
                                    22.5107498,
                                    22.4816456,
                                    22.4551868,
                                    22.434021,
                                    22.4102077,
                                    22.4128532,
                                    22.4207897,
                                    22.4366665,
                                    22.4551868,
                                    22.4842911,
                                    22.5186863,
                                    22.5636654,
                                    22.6139374,
                                    22.6139374
                                ],
                                "y": [
                                    19.7061672,
                                    19.6929379,
                                    19.682354,
                                    19.6744156,
                                    19.6744156,
                                    19.677063,
                                    19.6876469,
                                    19.7088108,
                                    19.7352695,
                                    19.7643738,
                                    19.8305187,
                                    19.8860836,
                                    19.9098949,
                                    19.9310627,
                                    19.9469376,
                                    19.954874,
                                    19.9469376,
                                    19.9257698,
                                    19.8887272,
                                    19.8887272
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    37,
                                    45,
                                    52,
                                    60,
                                    67,
                                    82,
                                    97,
                                    105,
                                    112,
                                    120,
                                    127,
                                    135,
                                    142,
                                    150,
                                    181
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ee4"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ee1",
                        "label": "avec"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ee5",
                        "label": "\n"
                    },
                    {
                        "candidates": [
                            "un",
                            "m",
                            "en",
                            "n",
                            "on"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    17.4281044,
                                    17.430748,
                                    17.4386883,
                                    17.4519157,
                                    17.4572086,
                                    17.4783745,
                                    17.48102,
                                    17.4889584,
                                    17.4942493,
                                    17.5074787,
                                    17.531292,
                                    17.5445194,
                                    17.5656872,
                                    17.6212482,
                                    17.6556454,
                                    17.6741676,
                                    17.692688,
                                    17.7191448,
                                    17.7694168,
                                    17.7773552,
                                    17.7747078,
                                    17.7164993,
                                    17.7217922,
                                    17.7323742,
                                    17.7561874,
                                    17.7799988,
                                    17.8011665,
                                    17.8223324,
                                    17.8435001,
                                    17.8831863,
                                    17.9043541,
                                    17.9175835,
                                    17.9440403,
                                    17.9625626,
                                    17.9625626,
                                    17.9599152,
                                    17.9599152,
                                    17.9387512,
                                    17.9281673,
                                    17.9255199,
                                    17.9308109,
                                    17.9440403,
                                    17.9572716,
                                    17.970499,
                                    17.9837284,
                                    18.0048962,
                                    18.1266041,
                                    18.2006874,
                                    18.2377281,
                                    18.2456665,
                                    18.253603,
                                    18.2641869,
                                    18.2615395,
                                    18.2271442,
                                    18.2244987,
                                    18.2430191,
                                    18.2774162,
                                    18.3250408,
                                    18.3514996,
                                    18.3673744,
                                    18.3753109,
                                    18.3885403,
                                    18.4044151,
                                    18.414999,
                                    18.4308758,
                                    18.4308758,
                                    18.4308758
                                ],
                                "y": [
                                    20.6613102,
                                    20.6639576,
                                    20.6666031,
                                    20.6692505,
                                    20.6718941,
                                    20.6930618,
                                    20.6983547,
                                    20.7009983,
                                    20.7062912,
                                    20.7089367,
                                    20.7089367,
                                    20.7062912,
                                    20.6983547,
                                    20.6666031,
                                    20.6401463,
                                    20.6189785,
                                    20.5872288,
                                    20.56077,
                                    20.4522915,
                                    20.4126034,
                                    20.4020195,
                                    20.5978107,
                                    20.6295624,
                                    20.6560211,
                                    20.6798344,
                                    20.6957073,
                                    20.7009983,
                                    20.6983547,
                                    20.6877708,
                                    20.6480827,
                                    20.6189785,
                                    20.5925217,
                                    20.5448952,
                                    20.4813957,
                                    20.4522915,
                                    20.4469986,
                                    20.465519,
                                    20.5925217,
                                    20.6242695,
                                    20.6480827,
                                    20.6639576,
                                    20.682478,
                                    20.6930618,
                                    20.6930618,
                                    20.6904144,
                                    20.6798344,
                                    20.5396023,
                                    20.4469986,
                                    20.4284782,
                                    20.4311256,
                                    20.449646,
                                    20.5131454,
                                    20.5528336,
                                    20.677187,
                                    20.7009983,
                                    20.6480827,
                                    20.5819378,
                                    20.5157909,
                                    20.4946251,
                                    20.4893341,
                                    20.4919777,
                                    20.4999161,
                                    20.5184383,
                                    20.5396023,
                                    20.6031036,
                                    20.6639576,
                                    20.6639576
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    67,
                                    75,
                                    82,
                                    90,
                                    97,
                                    112,
                                    120,
                                    127,
                                    142,
                                    150,
                                    158,
                                    165,
                                    173,
                                    203,
                                    218,
                                    225,
                                    300,
                                    308,
                                    315,
                                    323,
                                    330,
                                    338,
                                    345,
                                    353,
                                    368,
                                    375,
                                    383,
                                    398,
                                    420,
                                    435,
                                    443,
                                    473,
                                    503,
                                    510,
                                    518,
                                    525,
                                    533,
                                    541,
                                    548,
                                    556,
                                    563,
                                    601,
                                    623,
                                    638,
                                    646,
                                    661,
                                    676,
                                    683,
                                    706,
                                    721,
                                    758,
                                    773,
                                    788,
                                    796,
                                    803,
                                    811,
                                    818,
                                    826,
                                    833,
                                    848,
                                    863,
                                    911
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ee7"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ee6",
                        "label": "un"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985ee8",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "vrai",
                            "va",
                            "vu",
                            "ou",
                            "ow"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    19.0552902,
                                    19.0367699,
                                    19.020895,
                                    19.0050201,
                                    18.9812069,
                                    18.9838543,
                                    19.0103111,
                                    19.0261879,
                                    19.0447083,
                                    19.0658741,
                                    19.0817509,
                                    19.1002712,
                                    19.1214352,
                                    19.1743546,
                                    19.2113953,
                                    19.2272701,
                                    19.2299156,
                                    19.2272701,
                                    19.2193317,
                                    19.1928749,
                                    19.2087498,
                                    19.237854,
                                    19.3727913,
                                    19.3833752,
                                    19.3833752,
                                    19.3833752
                                ],
                                "y": [
                                    20.4946251,
                                    20.4840412,
                                    20.4840412,
                                    20.4946251,
                                    20.5792904,
                                    20.7062912,
                                    20.7644997,
                                    20.7856655,
                                    20.8015423,
                                    20.8068333,
                                    20.8041859,
                                    20.7962494,
                                    20.7803745,
                                    20.7327499,
                                    20.6877708,
                                    20.6427898,
                                    20.6136875,
                                    20.5872288,
                                    20.5687065,
                                    20.555479,
                                    20.5660629,
                                    20.5660629,
                                    20.5210819,
                                    20.5210819,
                                    20.5184383,
                                    20.5184383
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    30,
                                    53,
                                    75,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    150,
                                    165,
                                    180,
                                    188,
                                    195,
                                    203,
                                    218,
                                    256,
                                    271,
                                    301,
                                    308,
                                    316,
                                    348
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eea"
                            },
                            {
                                "x": [
                                    19.4786243,
                                    19.4680424,
                                    19.4574585,
                                    19.4442272,
                                    19.4309998,
                                    19.4204159,
                                    19.415123,
                                    19.4071865,
                                    19.404541,
                                    19.4071865,
                                    19.4177704,
                                    19.4362907,
                                    19.5024376,
                                    19.5421238,
                                    19.5791664,
                                    19.5950413,
                                    19.6056232,
                                    19.6162071,
                                    19.626791,
                                    19.632082,
                                    19.6135616,
                                    19.6082706,
                                    19.6082706,
                                    19.6135616,
                                    19.6241455,
                                    19.6506023,
                                    19.6902905,
                                    19.7326241,
                                    19.7670193,
                                    19.8305187,
                                    19.838459,
                                    19.8463955,
                                    19.8543339,
                                    19.859623,
                                    19.8649158,
                                    19.8622704,
                                    19.8543339,
                                    19.849041,
                                    19.8358116,
                                    19.7961235,
                                    19.7670193,
                                    19.7061672,
                                    19.6876469,
                                    19.6797066,
                                    19.682354,
                                    19.7035198,
                                    19.7193947,
                                    19.7326241,
                                    19.7537918,
                                    19.7987709,
                                    19.8728542,
                                    19.8993111,
                                    19.9284153,
                                    19.9363537,
                                    19.9363537,
                                    19.9363537,
                                    19.9151859,
                                    19.8966656,
                                    19.8993111,
                                    19.9072495,
                                    19.9178333,
                                    19.9284153,
                                    19.9363537,
                                    20.0130825,
                                    20.0554161,
                                    20.0792294,
                                    20.0792294,
                                    20.0818748,
                                    20.0739384,
                                    20.076582,
                                    20.0792294,
                                    20.0924587,
                                    20.1003952,
                                    20.1109791,
                                    20.121563,
                                    20.1321449,
                                    20.1321449,
                                    20.1294994,
                                    20.1136227,
                                    20.1056862,
                                    20.0951023,
                                    20.0871658,
                                    20.0818748,
                                    20.0792294,
                                    20.0818748,
                                    20.0871658,
                                    20.1003952,
                                    20.1003952
                                ],
                                "y": [
                                    20.727457,
                                    20.7248116,
                                    20.7195187,
                                    20.7168751,
                                    20.7115822,
                                    20.7036457,
                                    20.6957073,
                                    20.6692505,
                                    20.6507301,
                                    20.6216259,
                                    20.5872288,
                                    20.5581245,
                                    20.4946251,
                                    20.4681664,
                                    20.454937,
                                    20.4522915,
                                    20.4602299,
                                    20.4734573,
                                    20.4972706,
                                    20.5316658,
                                    20.611042,
                                    20.6613102,
                                    20.677187,
                                    20.6798344,
                                    20.6745415,
                                    20.6560211,
                                    20.6242695,
                                    20.5898743,
                                    20.5634155,
                                    20.5157909,
                                    20.510498,
                                    20.5052071,
                                    20.4946251,
                                    20.4866867,
                                    20.4761028,
                                    20.465519,
                                    20.4602299,
                                    20.4602299,
                                    20.4628735,
                                    20.4866867,
                                    20.5078545,
                                    20.5766449,
                                    20.6136875,
                                    20.6374989,
                                    20.6533737,
                                    20.677187,
                                    20.6851254,
                                    20.6877708,
                                    20.6851254,
                                    20.6613102,
                                    20.6031036,
                                    20.5766449,
                                    20.5316658,
                                    20.510498,
                                    20.4946251,
                                    20.4999161,
                                    20.555479,
                                    20.6189785,
                                    20.6454372,
                                    20.6639576,
                                    20.677187,
                                    20.682478,
                                    20.6798344,
                                    20.6136875,
                                    20.5581245,
                                    20.5184383,
                                    20.5157909,
                                    20.5184383,
                                    20.5528336,
                                    20.5792904,
                                    20.6904144,
                                    20.7301025,
                                    20.7301025,
                                    20.7195187,
                                    20.6877708,
                                    20.6507301,
                                    20.616333,
                                    20.5819378,
                                    20.5052071,
                                    20.4628735,
                                    20.4126034,
                                    20.3702698,
                                    20.3411655,
                                    20.3252907,
                                    20.3173542,
                                    20.3226452,
                                    20.3332291,
                                    20.3332291
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    30,
                                    38,
                                    46,
                                    61,
                                    68,
                                    76,
                                    83,
                                    91,
                                    106,
                                    113,
                                    121,
                                    128,
                                    136,
                                    143,
                                    151,
                                    158,
                                    173,
                                    188,
                                    196,
                                    203,
                                    211,
                                    218,
                                    226,
                                    233,
                                    241,
                                    271,
                                    278,
                                    286,
                                    293,
                                    301,
                                    308,
                                    316,
                                    323,
                                    331,
                                    338,
                                    353,
                                    361,
                                    376,
                                    383,
                                    391,
                                    398,
                                    413,
                                    421,
                                    428,
                                    436,
                                    451,
                                    466,
                                    474,
                                    489,
                                    496,
                                    511,
                                    534,
                                    556,
                                    571,
                                    579,
                                    586,
                                    594,
                                    601,
                                    609,
                                    631,
                                    646,
                                    653,
                                    661,
                                    668,
                                    683,
                                    691,
                                    713,
                                    728,
                                    736,
                                    743,
                                    751,
                                    758,
                                    766,
                                    773,
                                    788,
                                    796,
                                    803,
                                    811,
                                    819,
                                    826,
                                    834,
                                    849,
                                    856,
                                    901
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eeb"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985ee9",
                        "label": "vrai"
                    },
                    {
                        "candidates": [],
                        "strokes": [],
                        "_id": "6071cfd3357fff0f20985eec",
                        "label": " "
                    },
                    {
                        "candidates": [
                            "ma",
                            "ne",
                            "me",
                            "ans",
                            "mes"
                        ],
                        "strokes": [
                            {
                                "x": [
                                    20.7195187,
                                    20.7248116,
                                    20.727457,
                                    20.7248116,
                                    20.7248116,
                                    20.6904144,
                                    20.6374989,
                                    20.5978107,
                                    20.5845833,
                                    20.5766449,
                                    20.5739994,
                                    20.5739994,
                                    20.5766449,
                                    20.5898743,
                                    20.6136875,
                                    20.7036457,
                                    20.7248116,
                                    20.7353954,
                                    20.7327499,
                                    20.7195187,
                                    20.7089367,
                                    20.6983547,
                                    20.6877708,
                                    20.6904144,
                                    20.7089367,
                                    20.7406864,
                                    20.8200626,
                                    20.838583,
                                    20.8518105,
                                    20.8597488,
                                    20.8571033,
                                    20.838583,
                                    20.8094788,
                                    20.7988949,
                                    20.793602,
                                    20.7856655,
                                    20.7433338,
                                    20.7221661,
                                    20.7195187,
                                    20.7168751,
                                    20.7168751,
                                    20.7195187,
                                    20.7195187
                                ],
                                "y": [
                                    20.4946251,
                                    20.4893341,
                                    20.4866867,
                                    20.4734573,
                                    20.4999161,
                                    20.5898743,
                                    20.682478,
                                    20.7539158,
                                    20.7697906,
                                    20.7830219,
                                    20.7830219,
                                    20.7803745,
                                    20.7671452,
                                    20.7459793,
                                    20.7168751,
                                    20.6083946,
                                    20.5739994,
                                    20.5396023,
                                    20.510498,
                                    20.4866867,
                                    20.4761028,
                                    20.4681664,
                                    20.4628735,
                                    20.4681664,
                                    20.4840412,
                                    20.5131454,
                                    20.6031036,
                                    20.6322079,
                                    20.6639576,
                                    20.6983547,
                                    20.7327499,
                                    20.7909584,
                                    20.8253536,
                                    20.8332901,
                                    20.8359375,
                                    20.8279991,
                                    20.7909584,
                                    20.7644997,
                                    20.7565613,
                                    20.7539158,
                                    20.7539158,
                                    20.7539158,
                                    20.7539158
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    30,
                                    38,
                                    60,
                                    75,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    143,
                                    165,
                                    173,
                                    180,
                                    188,
                                    195,
                                    203,
                                    210,
                                    218,
                                    240,
                                    255,
                                    270,
                                    293,
                                    300,
                                    308,
                                    315,
                                    323,
                                    338,
                                    353,
                                    360,
                                    368,
                                    383,
                                    406,
                                    421,
                                    428,
                                    436,
                                    443,
                                    451,
                                    483
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eee"
                            },
                            {
                                "x": [
                                    20.9708748,
                                    20.9761658,
                                    20.9761658,
                                    20.9761658,
                                    20.9788113,
                                    20.9814587,
                                    20.9841022,
                                    20.954998,
                                    20.949707,
                                    20.944418,
                                    20.9417706,
                                    20.944418,
                                    20.944418,
                                    20.9629383,
                                    20.9761658,
                                    21.0184994,
                                    21.0476036,
                                    21.0740623,
                                    21.1005211,
                                    21.1322708,
                                    21.1322708
                                ],
                                "y": [
                                    20.3543949,
                                    20.3332291,
                                    20.3199997,
                                    20.3147068,
                                    20.3252907,
                                    20.3543949,
                                    20.3861465,
                                    20.6666031,
                                    20.7327499,
                                    20.7459793,
                                    20.7380409,
                                    20.727457,
                                    20.7168751,
                                    20.6613102,
                                    20.6348534,
                                    20.5925217,
                                    20.5713539,
                                    20.5581245,
                                    20.5528336,
                                    20.5475426,
                                    20.5475426
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    53,
                                    68,
                                    75,
                                    113,
                                    128,
                                    135,
                                    165,
                                    173,
                                    180,
                                    195,
                                    203,
                                    218,
                                    226,
                                    233,
                                    241,
                                    249,
                                    306
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985eef"
                            },
                            {
                                "x": [
                                    21.2037067,
                                    21.2089996,
                                    21.2142906,
                                    21.2142906,
                                    21.216938,
                                    21.2142906,
                                    21.2089996,
                                    21.216938,
                                    21.2275219,
                                    21.2354584,
                                    21.2486858,
                                    21.2724991,
                                    21.3068943,
                                    21.3386459,
                                    21.378334,
                                    21.3862705,
                                    21.3915615,
                                    21.399498,
                                    21.399498,
                                    21.4047909,
                                    21.4153748,
                                    21.4153748,
                                    21.4100819,
                                    21.3121872,
                                    21.2354584,
                                    21.2195816,
                                    21.2116451,
                                    21.2063541,
                                    21.2063541
                                ],
                                "y": [
                                    20.5237293,
                                    20.5157909,
                                    20.510498,
                                    20.510498,
                                    20.5157909,
                                    20.5845833,
                                    20.7036457,
                                    20.7327499,
                                    20.7459793,
                                    20.7512703,
                                    20.7459793,
                                    20.7221661,
                                    20.6877708,
                                    20.6454372,
                                    20.5845833,
                                    20.5581245,
                                    20.5422497,
                                    20.5263748,
                                    20.5184383,
                                    20.5157909,
                                    20.5369587,
                                    20.555479,
                                    20.5872288,
                                    20.8200626,
                                    20.9920406,
                                    21.0290833,
                                    21.0264378,
                                    21.0211449,
                                    21.0211449
                                ],
                                "t": [
                                    0,
                                    8,
                                    15,
                                    23,
                                    38,
                                    68,
                                    90,
                                    98,
                                    105,
                                    113,
                                    120,
                                    128,
                                    135,
                                    143,
                                    158,
                                    165,
                                    173,
                                    180,
                                    188,
                                    203,
                                    233,
                                    241,
                                    248,
                                    278,
                                    308,
                                    323,
                                    338,
                                    346,
                                    381
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ef0"
                            },
                            {
                                "x": [
                                    21.5741253,
                                    21.5794163,
                                    21.5820618,
                                    21.5873528,
                                    21.5899982,
                                    21.5979366,
                                    21.6085205,
                                    21.616457,
                                    21.6296864,
                                    21.6482067,
                                    21.7090626,
                                    21.7857895,
                                    21.8122501,
                                    21.8016663,
                                    21.7884369,
                                    21.7752094,
                                    21.7646255,
                                    21.7355213,
                                    21.6773129,
                                    21.6270409,
                                    21.6191025,
                                    21.6217499,
                                    21.6296864,
                                    21.6429176,
                                    21.6534977,
                                    21.6799583,
                                    21.7249374,
                                    21.7699165,
                                    21.8519363,
                                    21.8836861,
                                    21.9048538,
                                    21.9154377,
                                    21.9207287,
                                    21.9260216,
                                    21.9260216,
                                    21.9207287,
                                    21.8942699,
                                    21.8836861,
                                    21.8731022,
                                    21.8651657,
                                    21.828125,
                                    21.8043137,
                                    21.7884369,
                                    21.7804985,
                                    21.7857895,
                                    21.7963734,
                                    21.8175411,
                                    21.8360615,
                                    21.8863335,
                                    21.9286652,
                                    21.9657078,
                                    21.989521,
                                    21.9974575,
                                    21.9974575,
                                    21.9842281,
                                    21.9709988,
                                    21.9630623,
                                    21.9551258,
                                    21.9498329,
                                    21.9418945,
                                    21.9313126,
                                    21.9233742,
                                    21.9233742
                                ],
                                "y": [
                                    20.6427898,
                                    20.6507301,
                                    20.6560211,
                                    20.6613102,
                                    20.6666031,
                                    20.6745415,
                                    20.6745415,
                                    20.6718941,
                                    20.6745415,
                                    20.6533737,
                                    20.5978107,
                                    20.4972706,
                                    20.4046669,
                                    20.2538548,
                                    20.2353344,
                                    20.232687,
                                    20.2353344,
                                    20.2697296,
                                    20.3887901,
                                    20.5792904,
                                    20.677187,
                                    20.6957073,
                                    20.7115822,
                                    20.7195187,
                                    20.7168751,
                                    20.7036457,
                                    20.6798344,
                                    20.6533737,
                                    20.6057491,
                                    20.5898743,
                                    20.5792904,
                                    20.5713539,
                                    20.5634155,
                                    20.5501862,
                                    20.5422497,
                                    20.5290184,
                                    20.5078545,
                                    20.5052071,
                                    20.5078545,
                                    20.5157909,
                                    20.5634155,
                                    20.6083946,
                                    20.6560211,
                                    20.7009983,
                                    20.7327499,
                                    20.7592068,
                                    20.777729,
                                    20.7856655,
                                    20.7671452,
                                    20.7459793,
                                    20.7168751,
                                    20.6877708,
                                    20.6639576,
                                    20.6401463,
                                    20.5845833,
                                    20.5634155,
                                    20.5581245,
                                    20.555479,
                                    20.555479,
                                    20.5528336,
                                    20.555479,
                                    20.56077,
                                    20.56077
                                ],
                                "t": [
                                    0,
                                    7,
                                    15,
                                    22,
                                    30,
                                    45,
                                    60,
                                    67,
                                    75,
                                    83,
                                    98,
                                    120,
                                    135,
                                    158,
                                    165,
                                    173,
                                    180,
                                    195,
                                    210,
                                    233,
                                    248,
                                    255,
                                    263,
                                    270,
                                    278,
                                    285,
                                    293,
                                    300,
                                    315,
                                    323,
                                    330,
                                    338,
                                    345,
                                    353,
                                    360,
                                    368,
                                    383,
                                    390,
                                    398,
                                    405,
                                    420,
                                    428,
                                    435,
                                    443,
                                    450,
                                    458,
                                    465,
                                    473,
                                    488,
                                    496,
                                    503,
                                    511,
                                    518,
                                    526,
                                    541,
                                    548,
                                    556,
                                    563,
                                    571,
                                    578,
                                    586,
                                    593,
                                    624
                                ],
                                "p": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "i": [],
                                "o": [],
                                "_id": "6071cfd3357fff0f20985ef1"
                            }
                        ],
                        "_id": "6071cfd3357fff0f20985eed",
                        "label": "ma"
                    }
                ],
                "label": "va www.ae\nrue, ici avec\nun vrai ma"
            },
            "__v": 0
        }
    ],
    "tagList": [],
    "_id": "6071cfd3357fff0f20985ef2",
    "user_id": 1,
    "width": 1920,
    "height": 1080,
    "__v": 0
};
export default note3;