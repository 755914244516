import qs         from 'qs';
import axios      from 'axios';
import { notify } from '../utils/notifications';
const AUTH_URL = `http://192.168.1.95:9001`

export const SET_LOGIN = 'login:login';

export function setLoggedIn(loggedIn) {
  return {
    type: SET_LOGIN,
    payload: loggedIn
  };
}

export function logIn(email, password) {
  return (dispatch, getState) => {
    var data = qs.stringify({
      'email': email,
      'password': password
    });

    var config = {
      method: 'post',
      url: `${AUTH_URL}/auth/login`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      dispatch(setLoggedIn(true));
      localStorage.setItem('user', JSON.stringify(response.data));
    })
    .catch(function (error) {
      notify("Connexion impossible", ' ', 'warning', true);
      dispatch(setLoggedIn(false));
      console.log(error);
    });
  }
}

export function logOut() {
  return (dispatch, getState) => {
    localStorage.removeItem('user');
  }
}
