import {
  SET_CONTACTS,
  SET_DRIVE_FILES
} from '../actions/google-actions';

export default function googleReducer(state='', { type, payload }) {
  switch (type) {
    case SET_CONTACTS:
    return {
      ...state,
      contacts: payload
    }
    case SET_DRIVE_FILES:
    return {
      ...state,
      driveFiles: payload
    }
    default:
    return state;
  }
}


// selectors
export function getContacts(state) {
  return state.googleReducer.contacts;
}

export function getDriveFiles(state) {
  return state.googleReducer.driveFiles;
}
