import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import CanvasDraw from "react-canvas-draw";
import { BlockList } from 'net';
import ContentEditable from 'react-contenteditable'
import { useHandWritting } from '../../useIsHandWritten';
import { getAllConvertedStrokes, getGlobalBB } from '../../../helpers';
import { fetchSessionActions, fetchSessions } from '../../../actions/storage-actions';



export default (props: any) => {

    const handWritting: any = useHandWritting();
    console.log("PROPSSS", props)
    const session = JSON.parse(props?.node?.attrs?.data);

    const scale = 0.7;
    // const scale = 15.0;
    const strokes = getAllConvertedStrokes(session, scale);
    const bb = getGlobalBB(session.elements)
    const final_data = {
        width: (bb.width + bb.x) * scale,
        height: (bb.height + bb.y) * scale,
        lines: strokes
    }

    const RenderWords = () => {
        return (
            <NodeViewContent />
        )
    }

    const RenderHandWriting = () => {
        const [loading, setLoading] = useState(false);
        useEffect(() => {
            console.log("LOADEDDDD")
        }, [])
        return (
            <>
                <CanvasDraw
                    canvasWidth={final_data.width}
                    canvasHeight={final_data.height}
                    // disabled
                    hideGrid
                    brushRadius={1}
                    lazyRadius={1}
                    backgroundColor="transparent"
                    // immediateLoading={true}
                    // ref={canvasDraw => (this.loadableCanvas = canvasDraw)}
                    ref={canvasRef}
                    saveData={JSON.stringify(final_data)}
                // saveData={JSON.stringify(data)}
                />
            </>

        )
    }

    
    const canvasRef = useRef<any>(null);
    return (
        <NodeViewWrapper>
            {/* <NodeViewContent className="content" /> */}

            {handWritting?.active ? <RenderHandWriting /> : <RenderWords />}
        </NodeViewWrapper>

    )
}
