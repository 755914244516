import axios from 'axios';
import { notify } from '../utils/notifications';

export const SET_CONTACTS = 'google:set_contacts';
export const SET_DRIVE_FILES = 'google:SET_DRIVE_FILES';

export function setContacts(contacts) {
  return {
    type: SET_CONTACTS,
    payload: contacts
  };
}

export function setDriveFiles(files) {
  return {
    type: SET_DRIVE_FILES,
    payload: files
  };
}

export function addEventToCalendar(start, end, summary) {
  const accessToken = localStorage.googleAccessToken;
  return (dispatch, getState) => {
    console.log("getCalendars");
    axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=' + accessToken,
      {
        start: {
          'dateTime': start,
          'timeZone': 'Europe/Paris'
        },
        end: {
          'dateTime': end,
          'timeZone': 'Europe/Paris'
        },
        colorId: "3",
        summary
      })
      .then((resp) => resp.data) // Transform the data into json
      .then(function (data) {
        console.log("GET CALENDAR : ", data);
        notify('Evénement ajouté', `Votre événement "${summary}" à bien été à votre agenda`, 'success');
      })
  };
}

export function addContact(familyName, name, displayName, phones, mails, addresses) {
  const accessToken = localStorage.googleAccessToken;
  axios.post('https://people.googleapis.com/v1/people:createContact/?access_token=' + accessToken,
    {
      names:
        [
          {
            familyName: familyName,
            givenName: name,
            displayName: displayName,
          }
        ],
      phoneNumbers: phones.map(phone => { return { value: phone } }),
      addresses: addresses.map(address => { return { formattedValue: address } }),
      emailAddresses: mails.map(mail => { return { value: mail } })
    })
    .then((resp) => resp.data) // Transform the data into json
    .then(function (data) {
      notify('Contact ajouté', `${displayName} à bien été ajouté à vos contacts`, 'success');
    })
}

export function fetchContacts(accessToken) {
  return (dispatch, getState) => {
    console.log("fetchContacts");

    axios.get(' https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&access_token=' + accessToken + "&personFields=names,emailAddresses&pageSize=1000&alt=json")
      .then((resp) => resp.data) // Transform the data into json
      .then(function (data) {
        if (!data.connections) {
          notify("Aucun contacts", ` `, 'warning', true);
          return dispatch(setContacts([]));
        }

        const contacts = data.connections
          .filter(contact => contact.names)
          .map(contact => {
            return {
              name: contact.names[0].displayName,
              email: contact.emailAddresses ? contact.emailAddresses.map(email => email.value) : [],
              // phone: contact["gd$phoneNumber"] ? contact["gd$phoneNumber"].map(phone => phone.uri) : [],
            }
          })
          .filter(contact => contact.name && (contact.email.length > 0))
        // .filter(contact => contact.name && (contact.email.length > 0 || contact.phone.length > 0))
        // notify(`${contacts.length} contacts trouvés`, ` `, 'success', false);

        dispatch(setContacts(contacts));
      })
      .catch(error => {
        console.log("ERROR FETCHING CONTACTS", error)
      })
  };
}



// DRIVE

export function fetchDriveFiles(accessToken, styloFolderId) {
  return (dispatch, getState) => {
    console.log("fetchDriveFiles");
    axios({
      method: 'get',
      url: 'https://www.googleapis.com/drive/v3/files',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      params: {
        q: `trashed = false and parents in '${styloFolderId}'`
      }
    }).then(async function (response) {
      const files = await Promise.all(response.data.files.map(file => getFileContent(accessToken, file)));
      console.log("FETCH DRIVE FILES", files);
      return dispatch(setDriveFiles(files))
    }).catch(function (error) {
      console.log("FETCH DRIVE FILES error", error);
    });

    // return dispatch(setContacts([]));
  }
}

export const getStyloFolder = async (accessToken) => {
  return new Promise((resolve, reject) => {

    axios({
      method: 'get',
      url: 'https://www.googleapis.com/drive/v3/files',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      params: {
        q: "mimeType='application/vnd.google-apps.folder' and trashed = false and name='STYLO'"
      }
    }).then(function (response) {
      var folder = response.data.files[0];
      if (folder) {
        return resolve(folder.id);
      } else {
        // Créer un nouveau dossier "STYLO"
        var fileMetadata = {
          'name': 'STYLO',
          'mimeType': 'application/vnd.google-apps.folder'
        };
        return axios({
          method: 'post',
          url: 'https://www.googleapis.com/drive/v3/files',
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          data: fileMetadata
        });
      }
    }).then(function (response) {
      var folder = response.data;
      return resolve(folder.id);
    }).catch(function (error) {
      console.log(error);
      return reject(error)
    });
  });
}

const getFileContent = async (accessToken, file) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://www.googleapis.com/drive/v3/files/' + file.id + '?alt=media',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      responseType: 'text'
    }).then(function (response) {
      return resolve({ fileContent: response.data, ...file });
    })
      .catch((e) => {
        return reject(e);
      })

  })
}

const getFile = async (accessToken, fileName, styloFolderId) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://www.googleapis.com/drive/v3/files',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      params: {
        q: `trashed = false and name='${fileName}' and parents in '${styloFolderId}'`
      }
    }).then(async (response) => {
      var file = response.data.files[0];
      if (file) {
        const content = await getFileContent(accessToken, file);
        return resolve(content);
      } else {
        return resolve(false);
      }
    }).catch(function (error) {
      console.log(error);
      return reject(error);
    });
  });
}

const createFileInDrive = async (accessToken, styloFolderId, filename) => {
  const fileMetadata = {
    'name': filename,
    'parents': [styloFolderId],
    'mimeType': 'application/octet-stream'
  };

  console.log("createFileInDrive fileMetadata", fileMetadata)
  return new Promise((resolve, reject) => {

    axios({
      method: 'post',
      url: 'https://content.googleapis.com/drive/v3/files',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      data: fileMetadata
    }).then(function (response) {
      var fileId = response.data.id;
      return resolve(fileId);
    }).catch(function (error) {
      // Une erreur s'est produite lors de la création du fichier
      console.log(error);
      return reject(error);
    });
  })

}

const addContentToFile = (accessToken, fileId, prevData, dataToAdd) => {

  const content = `${prevData} \n ${dataToAdd}`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: 'https://www.googleapis.com/upload/drive/v3/files/' + fileId,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'text/plain'
      },
      data: content
    }).then(() => {
      return resolve(true);
    })
      .catch((e) => {
        return reject(e);
      });
  });
}

export const syncNoteToDrive = async (accessToken, filename, newText) => {
  try {
    const styloFolderId = await getStyloFolder(accessToken)
    console.log("FILEEEE styloFolderId", styloFolderId)
    const file = await getFile(accessToken, filename, styloFolderId);
    // file does not exist
    if (file == false) {
      const fileId = await createFileInDrive(accessToken, styloFolderId, filename)
      console.log("FILEEEE NOT FOUND createFileInDrive", fileId)
      addContentToFile(accessToken, fileId, "", newText)
    } else {
      const { id, fileContent } = file;
      console.log("FILEEEE FOUND", file)
      addContentToFile(accessToken, id, fileContent, newText)
    }
  } catch (error) {
    console.log("FILEEE ERRROR", error)
  }
}


