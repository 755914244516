import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setOpenActionId } from "../../actions/storage-actions";
import { getContacts } from "../../reducers/google-reducer";
import { getOpenActionId } from "../../reducers/storage-reducer";
import editor from "../Editor/editor";
import { addContact } from "../../actions/google-actions";

export const ActionButtonsContainer = styled.div`
  position: sticky;
  top: 0;
  align-self: flex-start;
  margin-left: 1px;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
`;

interface ActionButtonProps {
  picture: string,
  title: string,
}

export const ActionButton = styled.button<ActionButtonProps>`
  width: 3rem;
  height: 3rem;
  display: block;
  border: none;
  outline: 1px solid black;
  background: white;
  background-image: url(${(props) => props.picture}); 
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  cursor: pointer;
  &:focus,
  &:hover {
    &:after {
      /* content: ${(props) => props.title}; */
      content: "${(props) => props.title}";
      position: absolute;
      /* width: 15rem; */
      white-space: nowrap;
      left: 100%;
      height: 3rem;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      background: white;
      outline: 1px solid black;
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;


interface ActionContainerProps {
  color: string,
  open: boolean,
}
export const ActionContainer = styled.div<ActionContainerProps>`
  position: absolute;
  top: 0;
  width: 20rem;
  border: 1px solid black;
  overflow: hidden;
  transition: 0.4s;
  margin-left: ${props => props.open ? "calc(2rem - 4px)" : "0"};
  height: ${props => props.open ? "20rem" : "2rem"};
  z-index: ${props => props.open ? 2 : 1};

  display: flex;
  flex-flow: column;
  justify-content: space-between;
    
  /* margin-top: 0 !important; */
  background-color: white;
  & header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 2rem;
    width: 100%;
    border-bottom: 1px solid black;
    & .chip:after {
      content: "⬤";
      margin-left: 0.5rem;
      color: ${props => props.color};
      font-size: 0.8rem;
    }
  }

  & .title {
    font: normal normal medium 10px/39px TT Norms Pro;
    letter-spacing: 0.2px;
    color: #282D33;
    text-transform: uppercase;
  }

  & .close {
    width: 2rem;
    height: 100%;
    cursor: pointer;
    background: black;
    color: white;
    border: none;
    outline: 1px solid black;
    font-size: 1rem;
  }

  & .body {
    &::-webkit-scrollbar {
      display: none;
    }
    margin-bottom: auto;
    overflow: auto;
    padding: 1.5rem;
    & label {
      font: normal normal normal 12px/33px TT Norms Pro;
      letter-spacing: 0.05px;
      color: #0A0A0A;
    }
    & input,
    & textarea {
      border: none;
      width: 100%;
    }
  }

  & .action {
    letter-spacing: 0.24px;
    cursor: pointer;
    background-color: ${props => props.color};
    color: #FAFAFC;
    margin-top: 1rem !important;
    width: 100%;
    border: 0;
    min-height: 2rem;
    border-top: 1px solid black;
    font-weight: 500;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const MailAction = (props: any) => {
  const open: boolean = props.open;
  const action: any = props.action;
  const dispatch = useDispatch();
  const [body, setBody] = useState(action?.mail?.body?.reduce((acc: string, curr: any) => acc + "\n" + curr?.text?.label, "").trim() || "");
  const contacts = useSelector(getContacts);
  const [recipient, setRecipient] = useState(action?.mail?.recipient?.label || "")
  const matchingContacts = contacts.filter((contact: { name: string; }) => contact.name.toLowerCase().indexOf(recipient.toLowerCase()) >= 0);
  const email = matchingContacts.length > 0 ? matchingContacts[0].email[0] : recipient;
  const link = 'mailto:' + email + '?body=' + encodeURI(body);

  useEffect(() => {
    if (matchingContacts.length <= 0) return;
    setRecipient(matchingContacts[0].email[0])
  }, [contacts])

  useEffect(() => {
    editor.on("update", () => {
      const body = Array.from(document.querySelectorAll(`[data-body-${action?._id}]`))
        .map((elem: any) => elem.innerText)
        .join("\n");
      console.log("BODYS", action)
      setBody(body)
    })
  }, [])


  return (
    <ActionContainer open={open} color={"#ffa160"} className="action" data-action-id={action?._id}>
      <header onClick={() => dispatch(setOpenActionId(action._id))}>
        <span className="chip"></span>
        <p className="title">e-mail</p>
        <button className="close" onClick={(e) => { e.stopPropagation(); dispatch(setOpenActionId(null)) }}>x</button>
      </header>
      <div className="body">
        <label htmlFor="text">Texte</label>
        <textarea id="text" rows={6} value={body} onChange={(e) => setBody(e.target.value)}></textarea>
        <label htmlFor="text">DESTINATAIRES</label>
        <input type="text" placeholder="email@xxx.com" value={recipient} onChange={(e) => setRecipient(e?.target?.value)} />
      </div>
      <button className="action"><a href={link} target="_blank">ENVOYER LE MAIL</a></button>
    </ActionContainer>

  )
}

const ContactAction = (props: any) => {
  const open: boolean = props.open;
  const action: any = props.action;
  const dispatch = useDispatch();
  console.log("CONTACT ACTION", action)
  const [phone, setPhone] = useState(action?.contact?.phone);
  const [name, setName] = useState(action?.contact?.name);


  // useEffect(() => {
  //   editor.on("update", () => {
  //     const body = Array.from(document.querySelectorAll(`[data-body-${action?._id}]`))
  //       .map((elem: any) => elem.innerText)
  //       .join("\n");
  //     console.log("BODYS", action)
  //     setBody(body)
  //   })
  // }, [])


  return (
    <ActionContainer open={open} color={"#5680F5"} className="action" data-action-id={action?._id}>
      <header onClick={() => dispatch(setOpenActionId(action._id))}>
        <span className="chip"></span>
        <p className="title">Contact</p>
        <button className="close" onClick={(e) => { e.stopPropagation(); dispatch(setOpenActionId(null)) }}>x</button>
      </header>
      <div className="body">
        <label htmlFor="text">NOM</label>
        <input type="text" placeholder="John DOE" value={name} onChange={(e) => setName(e?.target?.value)} />
        <label htmlFor="text">TELEPHONE</label>
        <input type="text" placeholder="06.xx.xx.xx.xx" value={phone} onChange={(e) => setPhone(e?.target?.value)} />
      </div>
      <button className="action" onClick={() => {
        addContact("", name, name, [phone], [], [])
      }}>ENREGISTRER</button>
    </ActionContainer>

  )
}

export const Action = (props: any) => {
  const action: any = props.action;
  const openActionId = useSelector(getOpenActionId);
  const isOpen = openActionId == action._id;
  switch (action.type) {
    case "mail":
      return <MailAction open={isOpen} {...props} />
      break;
    case "contact":
      return <ContactAction open={isOpen} {...props} />
      break;
    default:
      return <h1>ACTION TYPE NOT RECOGNIZED {action.type}</h1>
      break;
  }
}
