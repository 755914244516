import React from 'react';
import NoteEditor from "./NoteEditor"

import "../../css/editdocument.scopped.css"
import editor from './editor';
import Header from '../Header';
import { useLocation, useParams } from 'react-router-dom';


const DocumentEditorToolBar = () => {
    console.log("EDITOR", editor)
    const toggleBold = () => editor.chain().focus().toggleBold().run();
    const toggleItalic = () => editor.chain().focus().toggleItalic().run();
    // @ts-ignore
    const toggleUnderline = () => editor.chain().focus().toggleUnderline().run();
    const toggleNumberedList = () => editor.chain().focus().toggleOrderedList().run();
    const toggleBulletList = () => editor.chain().focus().toggleBulletList().run();
    // @ts-ignore
    const toggleTextAlignCenter = () => editor.chain().focus().setHandWriting('center').run();
    return <div id="toolbar">
        <ul>
            <li> <img src="/images/toolbar/UNDO.svg" alt="" /> </li>
            <li> <img src="/images/toolbar/REDO.svg" alt="" /> </li>

            <li onClick={toggleBold}> <img src="/images/toolbar/bold.svg" alt="" /> </li>
            <li onClick={toggleItalic}> <img src="/images/toolbar/italic.svg" alt="" /> </li>
            <li onClick={toggleUnderline}> <img src="/images/toolbar/underline.svg" alt="" /> </li>

            <li onClick={toggleNumberedList}> <img src="/images/toolbar/numbered_list.svg" alt="" /> </li>
            <li onClick={toggleBulletList}> <img src="/images/toolbar/bullet_list.svg" alt="" /> </li>

            <li onClick={toggleTextAlignCenter}> <img src="/images/toolbar/H1.svg" alt="" /> </li>
            <li> <img src="/images/toolbar/H2.svg" alt="" /> </li>
        </ul>
    </div>
}

const DocumentEditor = (props: { type: "session" | "note" }) => {
    // const params = useParams()


    return (
        <>
            <Header />
            <div id="documentEditor">
                <div id="editorContainer">
                    <DocumentEditorToolBar />
                    <NoteEditor />
                </div>
            </div>
        </>
    )
}

export default DocumentEditor