import React, { Component, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import GoogleLogin from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import { fetchContacts, fetchDriveFiles, getStyloFolder } from '../actions/google-actions';
import styled from 'styled-components';
import { APP_NAME } from '../config';
import { fetchAllActions, fetchNotes, fetchSessions } from '../actions/storage-actions';


const SCOPES = [
  'https://www.google.com/m8/feeds/',
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar',
  'https://www.googleapis.com/auth/calendar.events',
  "https://www.googleapis.com/auth/contacts",
  "https://www.googleapis.com/auth/contacts.readonly",
  "https://www.googleapis.com/auth/directory.readonly",
  "https://www.googleapis.com/auth/profile.agerange.read",
  "https://www.googleapis.com/auth/profile.emails.read",
  "https://www.googleapis.com/auth/profile.language.read",
  "https://www.googleapis.com/auth/user.addresses.read",
  "https://www.googleapis.com/auth/user.birthday.read",
  "https://www.googleapis.com/auth/user.emails.read",
  "https://www.googleapis.com/auth/user.gender.read",
  "https://www.googleapis.com/auth/user.organization.read",
  "https://www.googleapis.com/auth/user.phonenumbers.read",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/drive.file"

].join(' ');

const SignInWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  h1 {
    font: normal normal 300 25px/30px TT Norms Pro;
    text-align: center;
    margin-bottom: 3rem;
  }

  #box {
    width: 300px;
    outline: 1px solid black;
    #header {
      padding: 0.4rem;
      border-bottom: 1px solid black;
      display: flex;
      align-items: center;
      :before {
        content: "●";
        color: #1CB5A7;
        font-size: 1.4rem;
        line-height: 1rem;
      }
      p {
        font: normal normal 500 10px/1rem TT Norms Pro;
        text-transform: uppercase;
        margin: 0;
        margin-left: 0.5rem;
      }
    }
    #body {
      padding: 1rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      p {
        margin: 0;
        font: normal normal 300 16px/22px TT Norms Pro;
        margin-bottom: 1rem;
        padding: 1rem;
      }
    }
  }


`

const GoogleConnect = (props: { children: React.ReactElement }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const dispatch = useDispatch();

  const responseGoogle = async (authResult: any) => {
    console.log("AUTHRESULT : ", authResult);
    const accessToken = authResult.accessToken;
    setLoggedIn(true);
    // store the google accessToken to the localStorage
    localStorage.setItem("googleAccessToken", accessToken);
    // localStorage.setItem("googleuser", accessToken);
    dispatch(fetchContacts(accessToken));

    const styloFolderId = await getStyloFolder(accessToken);
    dispatch(fetchDriveFiles(accessToken, styloFolderId))

    fetchSessions(dispatch);
    fetchNotes(dispatch);
    fetchAllActions(dispatch);
  }

  const onLogoutSuccess = () => {
    setLoggedIn(false);
  }

  return loggedIn ?
    <div>
      <div id="logoutWrapper">
        <GoogleLogout
          clientId="680217145434-4rj527eev1ina05ln5fkrg0kklg2j0bl.apps.googleusercontent.com"
          buttonText="Se déconnecter"
          onLogoutSuccess={onLogoutSuccess}
        >
        </GoogleLogout>
      </div>
      {props.children}
    </div>
    :
    <SignInWrapper>

      <h1>Bonjour, <br /> Bienvenu chez {APP_NAME}</h1>

      <div id="box">
        <div id="header">
          <p>Se connecter avec google</p>
        </div>
        <div id="body">
          <p>Pour utiliser {APP_NAME}, vous devez vous connecter à vôtre compte Google.</p>
          <GoogleLogin
            clientId="680217145434-4rj527eev1ina05ln5fkrg0kklg2j0bl.apps.googleusercontent.com"
            buttonText="Se connecter"
            isSignedIn={true}
            onSuccess={responseGoogle}
            // onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            scope={SCOPES}
          />
        </div>
      </div >
    </SignInWrapper>


}
export default GoogleConnect;