import {
  Node,
  nodeInputRule,
  mergeAttributes,
} from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import DocumentViewRenderer from '../DocumentViewRenderer'

export interface HandWritingOptions {
  data: any,
}


export default Node.create<HandWritingOptions>({
  name: 'stylo-react-renderer',
  group: 'block',
  content: 'block*',
  priority: 1001,
  

  // content: 'inline*',
  // selectable: true,
  // atom: true,

  addAttributes() {
    return {
      data: {
        default: false
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(DocumentViewRenderer)
  },
})