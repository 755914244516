import React, { useState, useContext, useMemo } from 'react';

export interface GetLicencePopupProps {
    onClose?: Function,
    open: boolean,
}


export const HandWrittingActiveContext = React.createContext({});
export const useHandWritting = () => useContext(HandWrittingActiveContext);

export const IsHandWrittingActiveProvider = (props: any) => {
    const children = props.children;
    const [active, setActive] = useState<boolean>(false);

    const memoChild = useMemo(() => children, []);

    return (
        <HandWrittingActiveContext.Provider value={{ active, setActive }}>
            {memoChild}
        </HandWrittingActiveContext.Provider>
    );
};



