import { Node, mergeAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export interface StyloParagraphOptions {
    HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        styloParagraph: {
            /**
             * Toggle a styloParagraph
             */
            setStyloParagraph: () => ReturnType,
        }
    }
}

const StyloParagraph = Node.create<StyloParagraphOptions>({
    name: 'styloParagraph',

    priority: 1000,

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    addAttributes() {
        return {
            "mail-recipient": {
                default: undefined,
                renderHTML: attributes => {
                    if (attributes["mail-recipient"] && attributes["mail-recipient"].length > 0) {
                        let data: any = {
                            class: "mail mail-recipient",
                        };
                        attributes["mail-recipient"].forEach((mail: any) => data[`data-${mail._id}`] = "")
                        return data;
                    }
                },

            },
            "mail-body": {
                default: undefined,
                renderHTML: attributes => {
                    if (attributes["mail-body"] && attributes["mail-body"].length > 0)
                        return {
                            class: "mail mail-body",
                            ...attributes["mail-body"].reduce((prev: any, mail: any) => {
                                prev[`data-body-${mail._id}`] = true;
                                return prev;
                            }, {}),
                            "data-mail": attributes["mail-body"].map((mail: any) => mail._id).join("|"),
                        }
                },

            },
            "contact-data": {
                default: undefined,
                renderHTML: attributes => {
                    if (attributes["contact-data"] && attributes["contact-data"].length > 0)
                        return {
                            class: "contact contact-data",
                            ...attributes["contact-data"].reduce((prev: any, contact: any) => {
                                prev[`data-${contact._id}`] = true;
                                return prev;
                            }, {}),
                            "data-contact": attributes["contact-data"].map((contact: any) => contact._id).join("|"),
                        }
                },

            },
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('eventHandler'),
                props: {
                    handleClick(view, pos, event) {
                        const node = view.state.doc.resolve(pos).node();
                        const is_mail = Object.keys(node?.attrs).some(attr => attr.includes("mail") && node.attrs[attr]?.length > 0);
                        if (is_mail) {
                            console.log("CLICKED MAIL", node.attrs)
                        }
                        return true;
                    },

                },
            }),
        ]
    },

    group: 'block',

    content: 'inline*',

    parseHTML() {
        return [
            { tag: 'p' },
        ]
    },

    renderHTML(data) {
        const { HTMLAttributes } = data;
        console.log("DBG ERWAN", data)
        return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes,), 0]
    },

    addCommands() {
        return {
            setStyloParagraph: () => ({ commands }) => {
                return commands.setNode(this.name)
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Alt-0': () => this.editor.commands.setStyloParagraph(),
        }
    },
})

export default StyloParagraph;