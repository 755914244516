const findAllByKey: any = (obj: object, keyToFind: string) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      key === keyToFind
        ? acc.concat(value)
        : typeof value === "object"
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc,
    []
  );
};

const createStrokes = (strokes: [], scale = 10.0) =>
  strokes.reduce(
    (acc: any, curr: any) => [
      ...acc,
      {
        points: curr.x.map((_: any, idx: any) => ({
          x: curr.x[idx] * scale,
          y: curr.y[idx] * scale,
        })),
        brushColor: "#000",
        brushRadius: 1,
      },
    ],
    []
  );

export const getAllConvertedStrokes = (obj: object, scale = 10.0) => {
  const strokes = findAllByKey(obj, "strokes");
  const converted = createStrokes(strokes, (scale = scale));
  return converted;
};

export const getGlobalBB = (elements: Array<any>) => {
  console.log("getGlobalBB", elements)
  let x: number = Infinity;
  let y: number = Infinity;
  let x1: number = 0;
  let y1: number = 0;
  if (elements)
    elements.forEach((element) => {
      const elementBb = element.boundingBox;
      if (elementBb.x < x) {
        x = elementBb.x;
      }
      if (elementBb.y < y) {
        y = elementBb.y;
      }
      if (elementBb.x + elementBb.width > x1) {
        x1 = elementBb.x + elementBb.width;
      }
      if (elementBb.y + elementBb.height > y1) {
        y1 = elementBb.y + elementBb.height;
      }
    });

  return { x, y, width: x1 - x, height: y1 - y };
};

export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const options: any = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  return date.toLocaleDateString("fr-FR", options);
};

export const formatHour = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${date.getHours()}h${date.getMinutes()}`
}