import { Switch } from "@material-ui/core";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActions } from "../reducers/storage-reducer";
import { Action } from "./styloActions/actions";
import { useState } from "react";
const Wrapper = styled.header<{ actionsOpen: boolean }>`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #51575d;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;

  & > .logo {
    flex: 0;
    margin-left: 2em;
    margin-right: 2em;
    cursor: pointer;
  }

  & > .penName {
    margin: 0;
    margin-left: 2em;
  }

  & > .actionsToReview {
    z-index: 999;
    width: 30rem;
    height: 100%;
    border-left: 1px solid black;
    margin-left: auto;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    position: relative;

    .header {
      display: flex;
      align-items: center;
      cursor: pointer;
      .nbr {
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        color: white;
        background: #51575d;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-right: 0.5em;
      }
    }

    #actions {
      ::-webkit-scrollbar {
        display: none;
      }
      transition: 0.4s;
      overflow: scroll;
      overflow-x: hidden;
      height: ${props => props.actionsOpen ? 'calc(100vh - 5rem) !important' : '0'};
      padding-top: ${props => props.actionsOpen ? '2rem' : '0'};
      border-top: 1px solid black;
      background: white;
      position: absolute;
      left: -1px;
      top: 100%;
      width: 100%;
      gap: 2rem;
      display: block;
      box-sizing: border-box;
      border-left: 1px solid black;
      padding: 0 2rem;
      div:first-child {
        margin-top: 2rem !important;
      }
      div:last-child {
        margin-bottom: 2rem !important;
      }
      .action {
        width: 100%;
        margin-top: 2rem !important;
        position: relative;
        margin-left: 0 !important;
        height: auto !important;
      }
    }
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const actions = useSelector(getActions);
  const [actionsOpen, setActionsOpen] = useState(false);
  const toggleActions = () => {
    setActionsOpen(!actionsOpen);
  }

  return (
    <Wrapper id="stylo-header" actionsOpen={actionsOpen}>
      <h1 onClick={() => navigate('/')} className="logo">Notably.</h1>
      <Switch />
      <p className="penName">Mon stylo</p>
      <div className="actionsToReview">
        <div
          onClick={toggleActions}
          className="header"
        >
          <p className="nbr">{actions.length}</p>
          <p>ACTIONS À VÉRIFIER</p>
        </div>

        <div id="actions">
          {actions.map((action: any) => <Action action={action} />)}
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
