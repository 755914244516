export const APP_NAME = "STYLO";

export const protos = ["SendMail", "RDV", "Underline", "Contacts"]


export const myscript = {
  triggers: {
    exportContent: 'DEMAND',
    //exportContent: 'POINTER_UP',
    addStrokes: 'POINTER_UP'
  },
  recognitionParams: {
	type: 'Raw Content',
	protocol: 'REST',
    apiVersion: 'V4',
    server: {
      applicationKey: 'ad8f05ec-d4d6-44ea-af9b-65613e20672c',
      hmacKey: '9072ac2b-4943-46f8-8583-f1cde304d1cb'
    }, // server
    v4: {
      lang: 'fr_FR',
      export: {
        jiix: {
          'bounding-box': true,
          strokes: true,
          text: {
            chars: false,
            words: true
          } // text
        } // jiix
      }, // export
	  'raw-content': {
		  /*
			Il est écrit nul part qu'il faut préciser un mimeTypes dans raw-content...
			On voit ça uniquement dans le code source de l'exemple !
			https://github.com/MyScript/MyScriptJS/blob/master/examples/v4/rest/rest_raw_content_iink.html
		  */
		  mimeTypes: ['application/vnd.myscript.jiix'],
		  recognition: {
			  text: true,
			  shape: true
		  }
	  } // raw-content
    } // v4
  } // recognitionParams
}
